<template>
  <div>
    <div class="container">
      <div class="row">
        <!-- Navigation buttons -->
        <div id="nav-buttons" class="d-flex align-items-center">
          <a @click="$router.back()" class="nav-link color-primary cursor-pointer">{{ $t("voltar")}}</a><span class="h-separator align-items-center color-primary"> | </span><router-link :to="{ name: 'Events'}" class="nav-link color-primary cursor-pointer">{{ $t("verTodos")}}</router-link> 
        </div>
        <div class="col-12 col-md-8">
          <!-- Title -->
          <div class="title-section col-12 col-md-8">
            <h6>{{startDate + ' - ' + endDate + ' | ' + category}}</h6>
            <h3 class="fw-bold">{{eventDetail.title}}</h3>
          </div>
          <!-- XS, SM Gallery -->
          <ThreePartGallery v-if="gallery != null" class="d-md-none col-12 my-3" :gallery="gallery"></ThreePartGallery>
          <!-- Descriptions -->
          <div class="description mt-5" v-html="eventDetail.description"></div>
          <div class="description mt-5" v-html="eventDetail.detail"></div>
        </div>
        <!-- MD+ Gallery -->
        <ThreePartGallery v-if="gallery != null" class="d-none d-md-block col-4 my-5" :gallery="gallery"></ThreePartGallery>
      </div>
    </div>
    <iframe class="mt-3" width="100%" height="320" :src="mapUrl" title="Main content" name="contents"></iframe>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";
import ThreePartGallery from "@/components/ThreePartGallery.vue";
var moment = require('moment');


export default {
  components: {ThreePartGallery},
  props: {
    eventDetail: Object
  },
  mounted: function()
    {
      moment.locale(this.$i18n.locale)
    },
  computed:{
    gallery: function()
    {
      return this.eventDetail && this.eventDetail.files && this.eventDetail.files.length ? this.eventDetail.files.map(file=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file.path])) : [require("../assets/poiDefault.jpg")];
    },
    startDate: function (){
      return this.eventDetail.startDate ? UTILS_HELPER.formatDate_2(this.eventDetail.startDate) : "";
    },
    endDate: function (){
      return this.eventDetail.endDate ? UTILS_HELPER.formatDate_2(this.eventDetail.endDate) : "";
    },
    mapUrl: function()
    {
      return this.eventDetail ? `https://maps.google.com/maps?q=${this.eventDetail.latitude},${this.eventDetail.longitude}&hl=es;z=14&output=embed` : null;
    },
    category: function()
    {
      return this.eventDetail && this.eventDetail.categories && this.eventDetail.categories.length ? this.eventDetail.categories[0].name : "";
    }
  },
};
</script>

<style scoped>
a,
.h-separator
{
  font-size: 22px;
}

@media (min-width: 992px) {
  .container
  {
    margin-top: 3rem;
  }

  #nav-buttons
  {
    justify-content: flex-end;
  }
}

@media (max-width: 991px)
{
  .container
  {
    margin-top: 1rem;
  }

  #nav-buttons
  {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
</style>
