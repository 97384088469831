<template>
  <div>
    <div class="container">
      <div class="row">
        <!-- Navigation buttons -->
        <div id="nav-buttons" class="d-flex align-items-center">
          <a @click="$router.back()" class="nav-link color-primary cursor-pointer">{{ $t("voltar")}}</a><span class="h-separator align-items-center color-primary"> | </span><router-link :to="seeAllRoute" class="nav-link color-primary cursor-pointer">{{ $t("verTodos")}}</router-link> 
        </div>
        <div class="col-12 col-md-8">
          <!-- Title -->
          <div class="title-section d-flex align-items-center flex-wrap">
              <h6>{{category.toUpperCase()}}</h6>
              <span class="h-separator"> | </span>
              <h3 class="fw-bold">{{poiDetail.name}}</h3>
              <!-- <img class="px-4" src="../assets/estrelas.png" alt=""> -->
          </div>
          <!-- XS, SM Gallery -->
          <ThreePartGallery v-if="gallery != null" class="d-md-none col-12 my-3" :gallery="gallery"></ThreePartGallery>
          <!-- Descriptions -->
          <div class="description mt-5" v-html="poiDetail.description"></div>
        </div>
        <!-- MD+ Gallery -->
        <ThreePartGallery v-if="gallery != null" class="d-none d-md-block col-4 my-5" :gallery="gallery"></ThreePartGallery>
      </div>
    </div>
    <iframe class="mt-3" width="100%" height="320" :src="mapUrl" title="Main content" name="contents"></iframe>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";
import ThreePartGallery from "@/components/ThreePartGallery.vue";

export default {
  components: {ThreePartGallery},
  props: {
    poiDetail: Object
  },
  computed:{
    gallery: function()
    {
      return this.poiDetail && this.poiDetail.files && this.poiDetail.files.length ? this.poiDetail.files.map(file=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file.path])) : [require("../assets/poiDefault.jpg")];
    },
    mapUrl: function()
    {
      return this.poiDetail ? `https://maps.google.com/maps?q=${this.poiDetail.latitude},${this.poiDetail.longitude}&hl=es;z=14&output=embed` : null;
    },
    seeAllRoute: function()
    {
      const result = this.$t("vueRoutes.pois");
      const type = this.poiDetail.categories[0].type;

      switch (type) 
      {
        case "See":
          return result + "?type=2";
        case "Eat":
          return result + "?type=3";
        case "Stay":
          return result + "?type=4";
      }

      return 'Home';
    },
    category: function()
    {
      return this.poiDetail && this.poiDetail.categories && this.poiDetail.categories.length ? this.poiDetail.categories[0].name : "";
    }
  }
};
</script>

<style scoped>
.title-section
{
  gap: 4px;
}

h6, h3{
  display: inline;
  margin: 0;
}

a,
.h-separator
{
  font-size: 22px;
}

@media (min-width: 992px) {
  .container
  {
    margin-top: 3rem;
  }

  #nav-buttons
  {
    justify-content: flex-end;
  }
}

@media (max-width: 991px)
{
  .container
  {
    margin-top: 1rem;
  }

  #nav-buttons
  {
    justify-content: center;
    margin-bottom: 1rem;
  }
}
</style>
