<template>
<div>
    <div class="container-fluid">
      <div class="row my-5 justify-content-between align-items-center">
          <div class="col-6 col-md-4 col-lg-3 title title-shape">
              <h2 class="align-self-center">{{ $t("agenda")}}</h2>
          </div>
          <div id="category-filter-container" class="col-12 col-md-6 col-lg-8 categories align-items-center">
              <CalendarFilter @change="onDatesChanged"/>
          </div>
          <div id="go-back-container" class="col-6 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
            <a @click="$router.back()" class="nav-link"><h5 class="color-primary cursor-pointer">{{ $t("voltar")}}</h5></a>
          </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="event-container d-flex flex-wrap mt-2 container">
      <SkeletonLoading v-for="(block, index) in (new Array(itemsPerPage))" :key="index"></SkeletonLoading>
    </div>
    <!-- Content -->
    <div v-else class="event-container d-flex flex-wrap mt-2 container">
      <EventThumb  class="slider-thumb" v-for="event in eventsOutput" :key="event.id" :eventContent="event"/>
      <NoResultsPlaceholder v-if="!eventsOutput.length"></NoResultsPlaceholder>
    </div>
    <div v-show="!loading && canLoadMore">
      <span @click="loadMore" class="icon-show-more cursor-eventnter d-block text-center mb-2"></span>
      <p @click="loadMore" class="d-block text-center">{{$t("carregarMais")}}</p>
    </div>
</div>
</template>

<script>
import EventThumb from "@/components/EventThumb.vue";
import CalendarFilter from "@/components/CalendarFilter.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  metaInfo () {
    return { 
          title: this.$t("vueRoutes.eventsTitle"), 
          titleTemplate: '%s - Alcobaça', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRoutes.eventsDescription")},
            {name: 'keywords', content: this.eventsOutput && this.eventsOutput.length ? this.eventsOutput.map(x=> x.title).join(", ") : this.$t("vueRoutes.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRoutes.eventsTitle")},
            {name: 'twitter:description', content: this.$t("vueRoutes.eventsDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRoutes.eventsTitle")},
            {property: 'og:site_name', content: 'Alcobaça'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
            {property: 'og:description', content: this.$t("vueRoutes.eventsDescription")}
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.events", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.events", 'en')])},
            {rel: 'alternate', hreflang: 'fr', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.events", 'fr')])},
            {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.events", 'es')])}
          ]
      } 
  }, 
  components:{EventThumb, CalendarFilter, NoResultsPlaceholder, SkeletonLoading},
  data(){
      return{
          itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      }
  },
  methods:{
  ...mapActions({
      getEvents: 'events/getList',
    }),
  updateItemsRequested: function(dates)
  {
    const currentPage = 1;
    const searchText = '';
    const sorter = 2; // desc
    const propertyName = "id";
    
    //Make request
    var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);
    // List specific parameters 
    input.visible = true;
    if(dates && dates.length)
    {
      input.date = dates;
    }

    this.getEvents(input);
  },
  loadMore()
  {
    if(this.canLoadMore)
    {
      this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
    }
  },
  onDatesChanged: function(categories)
  {
    this.updateItemsRequested(categories);
  },
},
computed:{
  ...mapState({ 
    eventsOutput: (state) => state.events.listOutput && state.events.listOutput.data && state.events.listOutput.data.events ? state.events.listOutput.data.events.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.startDate) - new Date(b.startDate);
      }) : null,
    datesOutput: state => state.events.dateListOutput
  }),
  loading: function(){
    return this.eventsOutput == null || this.datesOutput == null;
    },
  // events: function() {
  //   return this.eventsOutput && this.eventsOutput.data && this.eventsOutput.data.events ? this.eventsOutput.data.events : [];
  // },
  numEvents: function(){
      return this.eventsOutput && this.eventsOutput.totalRecords ? this.eventsOutput.totalRecords : 0;
    },
  canLoadMore: function()
    {
      return this.numEvents > this.itemsPerPage;
    },
  },
  mounted: function()
  {
    // Get events
    this.updateItemsRequested();
  },
  watch: 
  {
    itemsPerPage()
    {
      this.updateItemsRequested();
    }
  }
}
</script>

<style scoped>
/* Title */
.title-shape{
  height: 55px;
  border-top:2px solid #E31E24;
  border-right:2px solid #E31E24;
  border-bottom:2px solid #E31E24;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;
}

/* Header bar */
@media (max-width: 767px) /* Two columns */
{
  #category-filter-container
  {
    order: 3;
    margin: 2rem 0;
  }

  #go-back-container
  {
    order: 2;
  }
}

/* Size of event elements: these are not done using bootstrap to have correct alignments, using gap */
.event-container /* Four columns */
{
  gap: 4%;
}
.event-container > .slider-thumb,
.event-container > span
{
  width: 22%;
  margin-bottom: 4%;
}
@media (max-width: 1199px) 
{
  .event-container
  {
    gap: 5%;
    padding: 0 1rem;
  }

  .event-container > .slider-thumb,
  .event-container > span
  {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}
@media (max-width: 767px) /* Two columns */
{
  .event-container
  {
    gap: 10%;
    padding: 0 1rem;
  }

  .event-container > .slider-thumb,
  .event-container > span
  {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}
@media (max-width: 575px) /* Two columns */
{
  #category-filter-container
  {
    margin-bottom: 5rem;
  }

  .event-container
  {
    padding: 0 1rem;
  }

  .event-container > .slider-thumb,
  .event-container > span
  {
    width: 95%;
    max-width: 95%;
    margin-bottom: 50%;
  }
  .title h2{
   font-size: 20px;
  }
}

/* Overlays */
>>>.overlay {
    visibility: hidden;
    transition: 1s ease;
    background-color: hsla(1, 63%, 36%, 0);
    max-height: 30px;
    top: 0;
}
>>>.overlay_3 {
    visibility: hidden;
    transition: 1s ease;
    background-color: hsla(1, 63%, 36%, 0);
    max-height: 60px;
    bottom: 0;
}
>>>.overlay * {
  opacity: 0;
}
>>>.vueperslide__content-wrapper:hover .overlay * {
  opacity: 1;
}
>>>.overlay_3 * {
  opacity: 0;
}
>>>.vueperslide__content-wrapper:hover .overlay_3 * {
  opacity: 1;
}

/* Others */
[class*="icon-show-more"] {
  font-size: 80px;
  line-height: 25px;
}

.skeleton-box
{
  min-height: 275px;
}
</style>