<template>
  <!-- <section v-if="poiContent" id="WhereStayDetail"> -->
    <!-- <vueper-slides class="no-shadow" fixed-height="100vh" :bullets="false"  :touchable="false"> -->
      <!-- Custom arrows -->
      <!-- <template v-slot:arrow-left>
          <span class="icon-poisDetail_arrow_left"></span>
      </template>
      <template v-slot:arrow-right>
          <span class="icon-poisDetail_arrow_right"></span>
      </template> -->
      <!-- Content -->
      <!-- <vueper-slide class="teste" v-for="poi in poiContent" :key="poi.id">
        <template v-slot:content>
          <PoiDetail @show="showImage = true" :poiDetail="poiContent"/>
        </template>
      </vueper-slide>
    </vueper-slides> -->
    <section id="WhereStayDetail">
      <PoiDetail v-if="poiContent" @show="showGalleryRequested" :poiDetail="poiContent"/>
      <div class="mt-5 container" v-else-if="loadingContent">
        <SkeletonLoading style="height: 20vh" class="w-100 mt-5 mb-3"></SkeletonLoading>
        <SkeletonLoading style="height: 50vh" class="w-100 mb-5"></SkeletonLoading>
      </div>
  </section>
</template>

<script>
import PoiDetail from "@/components/PoiDetail.vue";
// import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import router from '@/router';
import { API_HELPER } from "@/helpers/api.js";
import SkeletonLoading from "@/components/SkeletonLoading.vue";
import { mapState, mapActions } from "vuex";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  metaInfo () {
    var result =
    { 
        title: this.poiContent ? `${this.poiContent.name} - Alcobaça` : "Alcobaça", 
        htmlAttrs: {
            lang: this.$i18n.locale,
        },
        meta: 
        [
            {name: 'description', content: this.poiContent && this.poiContent.description ? UTILS_HELPER.htmlToText(this.poiContent.description) : this.$t("intro")},
            {name: 'keywords', content: this.poiContent && this.poiContent.categories && this.poiContent.categories.length ? this.poiContent.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.poiContent ? `${this.poiContent.name} - Alcobaça` : "Alcobaça"},
            {name: 'twitter:description', content: this.poiContent && this.poiContent.description ? UTILS_HELPER.htmlToText(this.poiContent.description) : this.$t("intro")},
            {name: 'twitter:image', content: this.gallery && this.gallery.length ? this.gallery[0] : API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.poiContent ? `${this.poiContent.name} - Alcobaça` : "Alcobaça"},
            {property: 'og:site_name', content: 'Alcobaça'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: this.gallery && this.gallery.length ? this.gallery[0] : API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
            {property: 'og:description', content: this.poiContent && this.poiContent.description ? UTILS_HELPER.htmlToText(this.poiContent.description) : this.$t("intro")},
        ]
    } 

    if(this.$route.query.id)
    {
        result.link = 
        [
            {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.poisDetail", 'pt')])}?id=${this.$route.query.id}`},
            {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.poisDetail", 'en')])}?id=${this.$route.query.id}`},
            {rel: 'alternate', hreflang: 'fr', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.poisDetail", 'fr')])}?id=${this.$route.query.id}`},
            {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.poisDetail", 'es')])}?id=${this.$route.query.id}`}
        ];
    }

    return result;
  }, 
  components: {
  //  VueperSlides, VueperSlide, FullScreenImage, PoiDetail
   PoiDetail, SkeletonLoading
  },
 data: function () {
    return {
      poiContent: null,
      title: ''
    }
  },
  methods: {
    ...mapActions("pois", ["getDetail"]),
    initializeParams: function(){
      if(!this.$route.query.id)
          {
            router.push("/");
          }
          else
          {
            if(this.$route.params.poiContent)
            {
              this.poiContent = this.$route.params.poiContent;
            }
            else
            {
              this.getDetail(this.$route.query.id);
            }
          }
        }
  },
  computed:
  {
    ...mapState('pois', ['poiOutput']),
    loadingContent: function()
    {
      return this.poiOutput == null;
    }
  },
  mounted() {
      this.initializeParams();
  },
  watch:
  {
    poiOutput: function(val)
    {
      if(val != null)
      {
          if(val && val.data)
          {
            this.poiContent = val.data;
          }
          else
          {
            router.push("/");
          }
      }
    }
  }
}
</script>

<style scoped>
[class^="icon-poisDetail_arrow_left"], [class*="icon-poisDetail_arrow_right"] {
  font-size: 250px;
}
>>>.vueperslides__parallax-wrapper{
  /* height: 100vh; */
}

/*-------------------*/
.back{
    cursor: pointer;
}
h5, h3{
  display: inline;
}
.box1 {
  background-size: cover;
  cursor: pointer;
  max-width: 410px;
  max-height: 300px;
}
.box1 img {
  max-height: 300px;
}
.box2 {
  background-size: cover;
  cursor: pointer;
  max-width: 205px;
  max-height: 140px;
}
.box2 img {
  max-height: 140px;
}
.box3 {
  background-size: cover;
  cursor: pointer;
  max-width: 205px;
  max-height: 140px;
}
.box3 img {
  max-height: 140px;
}
@media (max-width: 768px) {
.box1 {
  max-width: none;
  max-height: none;
}
.box2 {
  max-width: none;
  max-height: none;
}
.box3 {
  max-width: none;
  max-height: none;
}
}

</style>