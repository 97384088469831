<template>
    <div class="no-results d-flex flex-column justify-content-center align-items-center p-5 w-100">
        <img class="no-results-img my-3" src="../assets/CMA_marca_positivo_horizontal.png" alt="logo"/>
        <span class="my-3 text-center">{{$t("404")}}</span>
        <button class="btn mt-3" @click="goHome">{{$t("goHome")}}</button>
    </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  
    metaInfo () {
      return { 
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: 
        [
          {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
          {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")}
        ], 
        link: [
          {rel: 'canonical', href: process.env.VUE_APP_URL},
        ]
      } 
    }, 
    methods: 
    {
        goHome: function()
        {
            this.$router.push("/");
        }
    }
}
</script>

<style scoped>
.no-results
{
    min-height: calc(100vh - 100px);
}

.no-results-img
{
    width: 30%;
    min-width: 200px;
}

.btn {
  background: #962422;
  color: white;
}
.btn:hover {
  color: white;
}

</style>