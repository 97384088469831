<template>
  <div class="home">
    <Hero/>
    <TrailsSlider :title="$t('oQueFazer').toUpperCase()" class="mt-5 mb-5"/> 
    <PoisSlider :title="$t('oQueVer').toUpperCase()" :type="2" class="mt-5 mb-5"/> 
    <PoisSlider :title="$t('ondeComer').toUpperCase()" :type="3" class="mt-5 mb-5"/>
    <PoisSlider :title="$t('ondeFicar').toUpperCase()" :type="4" class="mt-5 mb-5"/> 
    <Map class="mt-5 mb-5"/>
    <EventSlider :title="$t('agenda').toUpperCase()" class="mt-5 mb-5"/>
    <Support class="mt-5"/>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero.vue";
import PoisSlider from "@/components/PoisSlider.vue";
import TrailsSlider from "@/components/TrailsSlider.vue";
import Map from "@/components/map.vue";
import EventSlider from "@/components/EventSlider.vue";
import Support from "@/components/Support.vue";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  props: { hash: String }, // Custom hash system
  metaInfo () {
    return { 
      title: 'Alcobaça',
      htmlAttrs: {
          lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: 'Alcobaça'},
        {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: 'Alcobaça'},
        {property: 'og:site_name', content: 'Alcobaça'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
        {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))}
      ], 
      link: [
        {rel: 'alternate', hreflang: 'pt', href: process.env.VUE_APP_URL},
        {rel: 'alternate', hreflang: 'en', href: process.env.VUE_APP_URL},
        {rel: 'alternate', hreflang: 'fr', href: process.env.VUE_APP_URL},
        {rel: 'alternate', hreflang: 'es', href: process.env.VUE_APP_URL}
      ]
    } 
  }, 
  name: 'Home',
  components: {
    Hero,
    TrailsSlider,
    PoisSlider,
    EventSlider,
    Map,
    Support,
  },
  methods:
  {
    processHash: function()
    {
      var result = false;

      if(this.hash && document.getElementById(this.hash))
      {
        document.getElementById(this.hash).scrollIntoView({block: 'end'});
        this.$emit('hash-requested', null);

        result = true;
      }

      return result;
    }
  },
  mounted: function()
  {
    if(!this.processHash())
    {
      setTimeout(function () {
        window.scrollTo(0, 0);
      },100);
    }
  },
  watch:
  {
    hash: function()
    {
      this.processHash();
    }
  }
}
</script>
