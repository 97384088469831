<template>
  <section>
    <div id="map-container" class="position-relative">
      <div id="map" style="width: 100%; height: 670px;"></div>
      <div v-show="selectedPoi" id="popup" class="ol-popup cursor-pointer" @click="onPoiClicked">
        <div v-if="selectedPoi" id="popup-content">{{selectedPoi.name}}</div>
      </div>
      <div class="buttons position-absolute">
        <button :class="{'selected': selectedType == SEE_TYPE}" type="button" class="btn btn-shape" @click="selectedType = selectedType == SEE_TYPE ? null : SEE_TYPE"><span>{{ $t("map.oQueVer")}}</span></button>
        <button :class="{'selected': selectedType == EAT_TYPE}" type="button" class="btn btn-shape" @click="selectedType = selectedType == EAT_TYPE ? null : EAT_TYPE"><span>{{ $t("map.ondeComer")}}</span></button>
        <button :class="{'selected': selectedType == STAY_TYPE}" type="button" class="btn btn-shape" @click="selectedType = selectedType == STAY_TYPE ? null : STAY_TYPE"><span>{{ $t("map.ondeFicar")}}</span></button>
      </div>
    </div>
  </section>
</template>

<script>
import ol from '../../public/ol.js';
import {Overlay} from '../../public/ol.js';
import { mapState } from "vuex";

const MAP_URL = "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png";
const MAP_LAT = 39.670992062375085;
const MAP_LNG = -8.74992370605469;
const MAP_DEFAULT_ZOOM = 11;

//

const SEE_TYPE = 2;
const EAT_TYPE = 3;
const STAY_TYPE = 4;

export default {
  data(){
    return{
      map: null,
      layers: [],
      selectedPoi: null,
      selectedType: null,
      //
      SEE_TYPE: SEE_TYPE,
      EAT_TYPE: EAT_TYPE,
      STAY_TYPE: STAY_TYPE
    }
  },
  mounted: function()
  {
    this.initializeMap();
  },
  computed:
  {
    ...mapState('pois', ['groupedListOutput']),
    groupedPois: function()
    {
      var result = [];

      if(this.groupedListOutput)
      {
        for (var key in this.groupedListOutput) {
          var value = this.groupedListOutput[key];
          result[key] = value && value.data && value.data.pois ? value.data.pois : [];
        }
      }
      return result;
    }
  },
  methods: 
  {
    initializeMap: function()
    {
      const container = document.getElementById('popup');
      const overlay = new Overlay({
        element: container,
        autoPan: true,
        autoPanAnimation: {
          duration: 250,
        },
      });

      this.map = new ol.Map({
        target: "map",
        overlays: [overlay],
        layers: [
            new ol.layer.Tile({
                source: new ol.source.OSM({
                    url: MAP_URL
                })
            })
        ],
        view: new ol.View({
            center: ol.proj.fromLonLat([MAP_LNG, MAP_LAT]),
            zoom: MAP_DEFAULT_ZOOM,
        })
      });

      const self = this;
      this.map.on('singleclick', function (evt) {
        var feature = self.map.forEachFeatureAtPixel(evt.pixel, function(feature, layer) {
          self.selectedPoi = layer.get('poi');
          return feature;
        });

        if (feature) {
          const coordinate = evt.coordinate;
          overlay.setPosition(coordinate);
        }
        else
        {
          overlay.setPosition(undefined);
          self.selectedPoi = false;
        }
      });

      this.map.on("pointermove", function (evt) {
        var feature = self.map.forEachFeatureAtPixel(evt.pixel, function(feature) {
          return feature;
        });

        this.getTargetElement().style.cursor = feature ? 'pointer' : '';
      });
    },
    addMapPoints: function()
    {      
      const self = this;

      // Clear
      self.layers.forEach(function(layer)
      {
        self.map.removeLayer(layer);
      });

      // Add new
      for (var type in this.groupedPois) {
        var pois = this.groupedPois[type];
        if(pois && pois.length && (self.selectedType == null || parseInt(self.selectedType) == type))
        {
          pois.forEach(function(poi)
          {
            self.addMapPoint(poi);
          });
        }
      }
    },
    addMapPoint(poi) {
      var vectorLayer = new ol.layer.Vector({
        poi: poi,
        source:new ol.source.Vector({
          features: [new ol.Feature({
                geometry: new ol.geom.Point(ol.proj.transform([parseFloat(poi.longitude), parseFloat(poi.latitude)], 'EPSG:4326', 'EPSG:3857')),
            })]
        }),
        style: new ol.style.Style({
          image: new ol.style.Icon({
            anchor: [0.5, 0.5],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg"
          }),
          hover: true
        })
      });

      this.map.addLayer(vectorLayer); 
      this.layers.push(vectorLayer);
    },
    onPoiClicked: function()
    {
      if(this.selectedPoi)
      {
        this.$router.push({name: "PoisDetail", params: {poiContent: this.selectedPoi}, query: {id: this.selectedPoi.id} });
      }

      this.selectedPoi = null;
    }
  },
  watch: 
  {
    groupedListOutput: function()
    {
      var groups = Object.values(this.groupedListOutput).filter(function(value) { return value != null});
      if(groups.length == 3) // See, eat, stay
      {
        this.addMapPoints();
      }
    },
    selectedType: function()
    {
      this.selectedPoi = false;
      this.addMapPoints();
    }
  }
}
</script>

<style scoped>
#map-container {
  margin-top: 170px;
  margin-left: auto;
  margin-right: auto;
}
#map
{
  width: 100%;
  height: 670px;
  max-height: 60vh;
  border-top: 2px solid #a10d0d;
  border-bottom: 2px solid #a10d0d;
}
.buttons {
  top: -25px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 5vw;
}
.btn-shape {
  background: white;
  border: 1px solid #a10d0d;
  border-radius: 50px;
  color: #a10d0d;
  font-size: 20px;
  width: 20vw;
  max-width: 300px;
  min-width: 100px;
}
@media (min-width: 992px) {
  .btn-shape {
    font-size: 16px;
  }
}

.btn-shape.selected
{
  background: #a10d0d;
  color: white;
}
.btn-shape:hover {
  background: #a10d0d;
  color: white;
}
.btn-shape.selected:hover
{
  background: white;
  color: #a10d0d;
}

/* Pop up */
.ol-popup {
  position: absolute;
  background-color: white;
  box-shadow: 0 1px 4px rgba(0,0,0,0.2);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid #cccccc;
  bottom: 15px;
  left: -50px;
  min-width: 140px;
  max-width: 280px;
}
.ol-popup:after, .ol-popup:before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}
.ol-popup:after {
  border-top-color: white;
  border-width: 10px;
  left: 48px;
  margin-left: -10px;
}
.ol-popup:before {
  border-top-color: #cccccc;
  border-width: 11px;
  left: 48px;
  margin-left: -11px;
}
</style>
