/* Imports */
import { API_HELPER } from "@/helpers/api.js";

/* Exports */
// API services that can be invoked on the modules
export const POIS_SERVICE = {
    list,
    get
};

/* Consts*/
// Root service to build API url
const API_ROOT_SERVICE = "Pois";

/* Services */
function list(queryParams)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE]);    

    const requestOptions = {
        method: 'GET',
    }; 

    // console.log(queryParams);

    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, queryParams, requestOptions);
}

function get(poisId)
{
    // Build API call.
    var url = API_HELPER.pathJoin([process.env.VUE_APP_API_ROOT, API_ROOT_SERVICE, poisId]);    
    
    const requestOptions = {
        method: 'GET'
    }; 
    
    // Process API call, validating the token beforehand.
    return API_HELPER.apiCall(url, null, requestOptions);
}
