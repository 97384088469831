<template>
  <!-- Slides -->
  <vueper-slides
    style="padding: 0px 50px;"
    fixed-height="90px"
    class="no-shadow cursor-pointer mb-0"
    :gap="1"
    :bullets="false"
    :visible-slides="3"
    slide-multiple
    :dragging-distance="70"
    :touchable="false"
    :breakpoints="{
      1550: { visibleSlides: 3, slideMultiple: 3 },
      1300: { visibleSlides: 2, slideMultiple: 2 },
      670: { visibleSlides: 2, slideMultiple: 2 },
      570: { visibleSlides: 1, slideMultiple: 1 },
    }"
  >
    <!-- Custom arrows -->
    <template v-slot:arrow-left>
      <span class="icon-arrow-category-left"></span>
    </template>
    <template v-slot:arrow-right>
      <span class="icon-arrow-category-right"></span>
    </template>
    <!-- Content -->
    <vueper-slide v-for="category in categories" :key="category.id">
      <template v-slot:content>
        <div class="d-flex justify-content-center align-items-center">
          <p
            @click="categoryClicked(category.id)"
            :class="{ selected: isSelected(category.id) }"
            class="text-center"
          >
            {{ category.name }}
          </p>
        </div>
      </template>
    </vueper-slide>
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";

export default {
  components: { VueperSlides, VueperSlide },
  props: {
    type: Number,
  },
  data() {
    return {
      selected: [],
    };
  },
  mounted: function() {
    this.updateItemsRequested();
  },
  methods: {
    ...mapActions({
      getCategories: "categories/getList",
    }),
    categoryClicked: function(id) {
      const index = this.selected.indexOf(id);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(id);
      }

      this.$emit("change", this.selected);
    },
    isSelected(id) {
      return this.selected.includes(id);
    },
    updateItemsRequested() {
      // Get categories
      const itemsPerPage = 999;
      const currentPage = 1;
      const searchText = "";
      const sorter = 2; // desc
      const propertyName = "Name";
      var input = new InputGetList(
        itemsPerPage,
        currentPage,
        searchText,
        sorter,
        propertyName
      );
      // List specific parameters
      input.visible = true;
      if (this.type) {
        input.types = [this.type];
      }

      this.getCategories(input);
    },
  },
  computed: {
    ...mapState({
      categoriesOutput: (state) => state.categories.listOutput,
    }),
    categories: function() {
      return this.categoriesOutput &&
        this.categoriesOutput.data &&
        this.categoriesOutput.data.categories
        ? this.categoriesOutput.data.categories
        : null;
    },
  },
  watch: {
    type: function() {
      this.updateItemsRequested();
    },
  },
};
</script>

<style scoped>
[class^="icon-arrow-category-left"],
[class*="icon-arrow-category-right"] {
  font-size: 40px;
}
p {
  margin: 0;
  color: #e31e24;
  width: 65%;
}
>>> .vueperslides__track {
  margin: 2rem 0;
}
p.selected {
  border: 1px solid #e31e24;
  border-radius: 20px;
  padding: 0 10px;
  width: 65%;
}
p.selected::after {
  content: "  x  ";
  position: relative;
  left: 3%;
}
@media (max-width: 1728px) {
  p {
    width: 100%;
  }
  p.selected {
    width: 100%;
  }
}
@media (max-width: 1131px) {
  .vueperslides {
    padding: 0 50px !important;
  }
  p {
    width: 85%;
  }
  p.selected {
    width: 85%;
  }
}
@media (max-width: 834px) {
  .vueperslides {
    padding: 0 50px !important;
  }
  p {
    font-size: 12px;
    width: 70%;
  }
  p.selected {
    font-size: 12px;
    width: 70%;
  }
}

@media (max-width: 570px) {
  .vueperslides {
    padding: 0 50px !important;
  }
  p {
    width: 65%;
  }
  p.selected {
    width: 65%;
  }
}
@media (max-width: 400px) {
  .vueperslides {
    padding: 0 !important;
  }
}
</style>
