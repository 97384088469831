<template>
  <!-- <section v-if="eventContent" id="EventDetail"> -->
    <!-- <vueper-slides class="no-shadow" fixed-height="100vh" :bullets="false"  :touchable="false"> -->
      <!-- Custom arrows -->
      <!-- <template v-slot:arrow-left>
          <span class="icon-poisDetail_arrow_left"></span>
      </template>
      <template v-slot:arrow-right>
          <span class="icon-poisDetail_arrow_right"></span>
      </template> -->
      <!-- Content -->
      <!-- <vueper-slide class="teste" v-for="event in eventContent" :key="event.id">
        <template v-slot:content>
          <EventDetail @show="showImage = true" :eventDetail="eventContent"/>
        </template>
      </vueper-slide>
    </vueper-slides> -->
  <section id="EventDetail">
    <EventDetail v-if="eventContent" :eventDetail="eventContent"/>
    <div class="mt-5 container" v-else-if="loadingContent">
      <SkeletonLoading style="height: 20vh" class="w-100 mt-5 mb-3"></SkeletonLoading>
      <SkeletonLoading style="height: 50vh" class="w-100 mb-5"></SkeletonLoading>
    </div>
  </section>
</template>

<script>
import EventDetail from "@/components/EventDetail.vue";
// import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import router from '@/router';
import { API_HELPER } from "@/helpers/api.js";
import { mapState, mapActions } from "vuex";
import SkeletonLoading from "@/components/SkeletonLoading.vue";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  metaInfo () {
      var result =
      { 
          title: this.eventContent ? `${this.eventContent.title} - Alcobaça` : "Alcobaça", 
          htmlAttrs: {
              lang: this.$i18n.locale,
          },
          meta: 
          [
              {name: 'description', content: this.eventContent && this.eventContent.description ? UTILS_HELPER.htmlToText(this.eventContent.description) : this.$t("intro")},
              {name: 'keywords', content: this.eventContent && this.eventContent.categories && this.eventContent.categories.length ? this.eventContent.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},
              // Twitter Card
              {name: 'twitter:card', content: "summary"},
              {name: 'twitter:title', content: this.eventContent ? `${this.eventContent.title} - Alcobaça` : "Alcobaça"},
              {name: 'twitter:description', content: this.eventContent && this.eventContent.description ? UTILS_HELPER.htmlToText(this.eventContent.description) : this.$t("intro")},
              {name: 'twitter:image', content: this.gallery.length ? this.gallery[0] : API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
              //Facebook OpenGraph
              {property: 'og:title', content: this.eventContent ? `${this.eventContent.title} - Alcobaça` : "Alcobaça"},
              {property: 'og:site_name', content: 'Alcobaça'},
              {property: 'og:type', content: 'website'},
              {property: 'og:image', content: this.gallery.length ? this.gallery[0] : API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
              {property: 'og:description', content: this.eventContent && this.eventContent.description ? UTILS_HELPER.htmlToText(this.eventContent.description) : this.$t("intro")}
          ]
      } 

      if(this.$route.query.id)
      {
          result.link = 
          [
              {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.eventsDetail", 'pt')])}?id=${this.$route.query.id}`},
              {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.eventsDetail", 'en')])}?id=${this.$route.query.id}`},
              {rel: 'alternate', hreflang: 'fr', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.eventsDetail", 'fr')])}?id=${this.$route.query.id}`},
              {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.eventsDetail", 'es')])}?id=${this.$route.query.id}`}
           
          ];
      }

      return result;
  }, 
  components: {
    // EventDetail, VueperSlides, VueperSlide, FullScreenImage
    EventDetail, SkeletonLoading
  },
  data: function () {
    return {
      eventContent: null,
    };
  },  
  computed:{
    ...mapState('events', ['eventOutput']),
    gallery: function()
    {
      return this.eventContent && this.eventContent.files ? this.eventContent.files.map(file=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file.path])) : [];
    },
    loadingContent: function()
    {
      return this.eventOutput == null;
    }
  },
  methods: 
  {
    ...mapActions("events", ["getDetail"]),
  },
  mounted() {
    if(!this.$route.query.id)
    {
      router.push({ name: 'Events' });
    }
    else
    {
      if(this.$route.params.eventContent)
      {
        this.eventContent = this.$route.params.eventContent;
      }
      else
      {
        this.getDetail(this.$route.query.id);
      }
    }
  },
  watch:
  {
    eventOutput: function(val)
    {
      if(val != null)
      {
          if(val && val.data)
          {
              this.eventContent = val.data;
          }
          else
          {
              router.push({ name: 'Events' });
          }
      }
    }
  }
}
</script>

<style scoped>
[class^="icon-poisDetail_arrow_left"], [class*="icon-poisDetail_arrow_right"] {
  font-size: 250px;
}

</style>