import { MESSAGES_SERVICE } from "@/services/messages.service.js";

const state = { processTask: false };

const actions = {
    sendMessage({ dispatch, commit }, message) {
        dispatch('alert/clear', null, { root: true });
        commit('sendRequest');

        MESSAGES_SERVICE.send(message)
            .then(
                output => {
                    commit('sendSuccess', output);
                },
                error => {
                    // console.log(error);
                    commit('sendFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    }
};

const mutations = {
    /* Add */
    sendRequest(state) {
        state.processTask = null;
    },
    sendSuccess(state) {
        state.processTask = true;
    },
    sendFailure(state) {
        state.processTask = false;

    },
};

export const messages = {
    namespaced: true,
    state,
    actions,
    mutations
};