<template>
  <div v-if="eventContent" @click="sendClicked" class="vueperslide__content-wrapper cursor-pointer">
    <div class="position-relative content" :style="{'background-image': 'url(' + imageUrl + ')'}">
      <div class="overlay position-absolute d-flex align-items-center">
          <div class="card-voucher flex-grow-1">
            <div class="category-name text-white">
                <span>{{categoryName.toUpperCase()}}</span>
            </div>
          </div>
      </div>
      <div class="overlay_2 position-absolute w-100 d-flex justify-content-center align-items-center">
        <div class="date text-white mt-2">
          <p>{{startDate}}</p>
          <hr>
          <p>{{endDate}}</p>
        </div>
      </div>
      <div class="overlay_3 position-absolute">
        <div class="d-flex flex-column">
          <div class="event-title text-white mt-2">
            <span>{{eventContent.title.toUpperCase()}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UTILS_HELPER } from "@/helpers/utils.js";
import { API_HELPER } from "@/helpers/api.js";

export default {
  props: {
    eventContent: Object
  },
  computed: {
    imageUrl: function(){
      return this.eventContent.files && this.eventContent.files.length && this.eventContent.files[0].path ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.eventContent.files[0].path]) : [require("../assets/poiDefault.jpg")];
    },
    startDate: function (){
      return this.eventContent.startDate ? UTILS_HELPER.formatDate(this.eventContent.startDate) : "";
    },
    endDate: function (){
      return this.eventContent.endDate ? UTILS_HELPER.formatDate(this.eventContent.endDate) : "";
    },
    categoryName: function() {
      return this.eventContent.categories && this.eventContent.categories.length && this.eventContent.categories[0].name ? this.eventContent.categories[0].name : '';
    }
  },
  methods:{
    sendClicked(){
      this.$router.push({name: "EventsDetail", params: {eventContent: this.eventContent}, query : {id: this.eventContent.id} });
    }
  }
}
</script>

<style scoped>
/* In order to guarantee squares, there is a container */
.content
{
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Remaining structure */
.vueperslide__content-wrapper
{
  max-width: 275px;
  max-height: 275px;
  cursor: pointer;
}
.category-name {
  font-size: 17px;
}
.overlay {
  height: 100%;
  width: 100%;
  background-color:hsla(60, 0%, 35%,1);
  max-height: 275px;
}
.vueperslide .overlay
{
  visibility: hidden;
  transition: 1s ease;
  background-color: hsla(1, 63%, 36%, 0);
  max-height: 30px;
  top: 0;
}
.vueperslide .overlay * {
  opacity: 0;
}

.overlay_2{
top: 15%;
}
.overlay_2 p{
font-size: 23px;
margin: 0;
}
hr:not([size]) {
    height: 3px;
}
hr {
    margin: 0;
    opacity: 1;
}
.overlay_2 .date{
background: black;
width: 140px;
}
.overlay_3 {
  height: 100%;
  width: 100%;
  background-color:hsla(0, 85%, 34%, 1);
  max-height: 275px;
}
.vueperslide .overlay_3
{
  visibility: hidden;
  transition: 1s ease;
  background-color:hsla(1, 63%, 36%, 0);
  max-height: 60px;
  bottom: 0;
}
.vueperslide .overlay_3 * {
  opacity: 0;
}

.overlay_3 .event-title{
 width: 100%;
text-align: start;
font-size: 15px;
padding: 0 10px;
}

/* Show on hover and touch devices */
.vueperslide .vueperslide__content-wrapper:hover .overlay *,
.vueperslide .vueperslide__content-wrapper:hover .overlay_2 *,
.vueperslide .vueperslide__content-wrapper:hover .overlay_3 * {
  opacity: 1;
}
.vueperslide__content-wrapper:hover .overlay  {
  visibility: initial;
  background-color: hsla(0, 0%, 0%, 0.5);
;
}
.vueperslide__content-wrapper:hover .overlay_2  {
  visibility: initial;
}
.vueperslide__content-wrapper:hover .overlay_3  {
  visibility: initial;
  background-color: hsla(0, 85%, 34%, 1);
}

@media (hover: none) {
  .vueperslide .vueperslide__content-wrapper .overlay *,
  .vueperslide .vueperslide__content-wrapper .overlay_2 *,
  .vueperslide .vueperslide__content-wrapper .overlay_3 * {
    opacity: 1;
  }
  >>>.overlay *,
  >>>.overlay_3 * {
  opacity: 1!important;
}
  .vueperslide__content-wrapper .overlay  {
    visibility: initial;
    background-color: hsla(0, 0%, 0%, 0.5);
;
  }
  .vueperslide__content-wrapper .overlay_2  {
    visibility: initial;
  }
  .vueperslide__content-wrapper .overlay_3  {
    visibility: initial;
    background-color: hsla(0, 85%, 34%, 1);
  }
}
@media (max-width: 991px) {
  .category-name{
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .overlay_3 .event-title{
    font-size: 13px;
  }
}
</style>