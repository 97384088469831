import { TRAILS_SERVICE } from "@/services/trails.service.js";

const state = { listOutput: null, trailOutput: null};

const actions = {
    getList({ dispatch, commit }, inputGetTrails) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        TRAILS_SERVICE.list(inputGetTrails)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    // console.log(error);
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, trailId) {
        dispatch('alert/clear', null, { root: true });
        commit('detailRequest');
        
        TRAILS_SERVICE.get(trailId)
            .then(
                output => {
                    commit('detailSuccess', output);
                },
                error => {
                    commit('detailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Detail */
    detailRequest(state) {
        state.trailOutput = null;
    },
    detailSuccess(state, output) {
        state.trailOutput = output;
    },
    detailFailure(state) {
        state.trailOutput = false;
    },
};
export const trails = {
    namespaced: true,
    state,
    actions,
    mutations
};