<template>
   <div class="container-fluid" id="slider">
    <!-- Title -->
    <div class="d-flex justify-content-end">
      <div v-if="title" class="col-6 text-center title" :class="{'col-12': !loading && !events.length}">{{title}}</div>
      <div v-if="loading || (!loading && events.length)" class="show-more-container d-flex cursor-pointer align-items-center col-6 col-lg-3 pr-5 pr-lg-0">
        <span @click="sendClicked" class="icon-show-more"></span>
        <span @click="sendClicked">{{ $t("verTodos")}}</span>
      </div>
    </div>
    <!-- Slides -->
    <vueper-slides v-if="loading || (!loading && events.length)" style="padding: 0px 223px" fixed-height="275px" :touchable="true" class="no-shadow mt-4" :gap=1 :bullets="false" :visible-slides="5" slide-multiple autoplay :pause-on-hover="true" :dragging-distance="70" :breakpoints="{
        1550: { visibleSlides: 4, slideMultiple: 4 },
        1300: { visibleSlides: 3, slideMultiple: 3 },
        1100: { visibleSlides: 2, slideMultiple: 2 },
        670: { visibleSlides: 1, slideMultiple: 1 },
      }"
    >
      <!-- Custom arrows -->
      <template v-slot:arrow-left>
        <span class="icon-arrow-left"></span>
      </template>
      <template v-slot:arrow-right>
        <span class="icon-arrow-right"></span>
      </template>
      <!-- Content -->
      <vueper-slide v-for="(block,index) in blocks" :key="index">
        <template v-if="loading" v-slot:content>
          <SkeletonLoading class="w-100 h-100"></SkeletonLoading>
        </template>
        <template v-else v-slot:content>
          <EventThumb :eventContent="block"/>
        </template>
      </vueper-slide>
    </vueper-slides>
    <NoResultsPlaceholder v-else></NoResultsPlaceholder>
  </div>
</template>

<script>
import EventThumb from "@/components/EventThumb.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";
var moment = require('moment');


export default {
 components: { VueperSlides, VueperSlide, EventThumb, SkeletonLoading, NoResultsPlaceholder},
 props:{
  title: String,
  searchQuery: String
 },
data() {
    return {
      events: []
  }
},
methods:{
  ...mapActions({
    getEventslist: 'events/getList',
  }),
  sendClicked () {
    this.$router.push({name: 'Events'});
  },
  getList(){
    // GET list of pois
      const itemsPerPage = 10;
      const currentPage = 1;
      const searchText = this.searchQuery;
      const sorter = 2; // desc
      const propertyName = "id";
      
      //Make request
      var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
      
      // List specific parameters
      input.visible= true;

      this.getEventslist(input);
  }
},
computed:{
  ...mapState('events', ['listOutput']),
  ...mapState({
      eventsOutput: (state) => state.events.listOutput && state.events.listOutput.data && state.events.listOutput.data.events ? state.events.listOutput.data.events.sort(function(a,b){
          // Turn your strings into dates, and then subtract them
          // to get a value that is either negative, positive, or zero.
          return new Date(a.startDate) - new Date(b.startDate);
      }) : null,
    }),
 

  loading: function()
  {
    return this.listOutput == null;
  },
  blocks: function()
  {
    return this.loading ? new Array(5): this.events;
  },
},
mounted()
{
  this.getList();
  moment.locale(this.$i18n.locale)

},
watch:
  {
    eventsOutput: function()
    {
      this.events = this.listOutput  && this.listOutput.data && this.listOutput.data.events ? this.listOutput.data.events : []
    },
    searchQuery: function()
    {
      this.getList();
    }
  }
}
</script>
<style scoped>
[class^="icon-arrow-left"], [class*="icon-arrow-right"] {
  font-size: 150px;
}
[class*="icon-show-more"] {
  font-size: 60px;
}
.icon-arrow-left{
  margin-left: -47px;
}
.icon-arrow-right{
  margin-right: -47px;
}
.title{
  font-size: 30px;
}
a{
  text-decoration: none;
  color: black;
}
a:hover{
  color: black;
}
@media (max-width: 991px)
{
  .show-more-container
  {
    justify-content: center;
  }
}
@media (max-width: 1030px) {
  .vueperslides{
    padding: 0 90px!important;
  }
  .show-more{
    margin: 20px auto;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .vueperslides{
    padding: 0 35px !important;
  }
}
@media (max-width: 430px) {
  .vueperslides{
    padding: 0 56px !important;
  }
  .icon-arrow-left{
  margin-left: -80px;
  }
  .icon-arrow-right{
  margin-right: -80px;
  }
  .title{
    font-size: 20px;
  }
}

</style>