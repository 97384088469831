<template>
  <section id="footer">
    <div class="footer-dark" :style="{'background-color': '#CCCCCC'}">
      <footer>
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-lg-2 item">
              <router-link to="/" class="logo d-flex justify-content-center">
                <img src="../assets/CMA_marca_positivo_horizontal.png" alt="Logo" title="Logo"/>
              </router-link>
            </div>
            <div class="col-md-2 col-lg-2 item">
              <ul>
                <li><router-link to="/">{{ $t("footer.alcobaca") }}</router-link></li>
                <li><router-link :to="{ name: 'Trails'}">{{ $t("topMenu.oQueFazer")}}</router-link></li>
                <li><router-link :to="{ name: 'Pois', query: { type: 2 }}">{{ $t("topMenu.oQueVer")}}</router-link></li>
              </ul>
            </div>
            <div class="col-md-2 col-lg-2 item">
              <ul>
                <li><router-link :to="{ name: 'Pois', query: { type: 3 }}">{{ $t("footer.ondeComer")}}</router-link></li>
                <li><router-link :to="{ name: 'Pois', query: { type: 4 }}">{{ $t("footer.ondeFicar")}}</router-link></li>
                <li><router-link :to="{ name: 'Events'}">{{ $t("footer.agenda")}}</router-link></li>
              </ul>
            </div>
            <div class="col-md-2 col-lg-2 item">
              <ul>
                <!-- This logic, using the default hash navigation, is not working correctly, so a custom implementation was done -->
                <!-- <li><a href="#support">{{ $t("footer.contatos") }}</a></li>
                <li><a href="#directions">{{ $t("footer.comoChegar") }}</a></li> -->
                <li><a @click="$emit('hash-requested', 'support')">{{ $t("footer.contatos") }}</a></li>
                <li><a @click="$emit('hash-requested', 'directions')">{{ $t("footer.comoChegar") }}</a></li>
                <li><router-link :to="{ name: 'Downloads', params: {title: 'Downloads'}}">{{ $t("footer.downloads")}}</router-link></li>
              </ul>
            </div>
            <div class="col-md-2 col-lg-2 item">
              <ul>
                <li><router-link :to="{ name: 'StaticPage', query: {id: '2'}}">{{ $t("footer.politicaPrivacidade")}}</router-link></li>
                <li><router-link :to="{ name: 'StaticPage', query: {id: '3'}}">{{ $t("footer.termosCondicoes")}}</router-link></li>
                <li><router-link :to="{ name: 'StaticPage', query: {id: '4'}}">{{ $t("footer.utilizaçãoCookies")}}</router-link></li>
              </ul>
            </div>
            <div class="col-12 col-md-6 col-lg-2 item text-center">
              <div class="row  icons text-center">
                <IconsSocial></IconsSocial>
              </div>
              <div class="row img2">
                <div class="col-6 col-md-4 d-flex justify-content-center align-items-center">
                  <img src="../assets/footer/footer2.png" alt="">
                </div>
                <a target="_blank" href="https://www.cm-alcobaca.pt/pt/Default.aspx" rel="noopener" class="text-white col-6 col-md-8 text-cm">
                  <span>CM-ALCOBAÇA</span>
                </a>
              </div>
            </div>
            <div class="col-12 col-md-6 col-lg-12 item lastItem">
              <router-link :to="{ name: 'StaticPage', query: {id: '5'}}">
                <div class="row d-flex justify-content-center">
                  <p>{{ $t("footer.confinanciadoPor") }}</p>
                  <img src="../assets/footer/footer_3.png" alt="image" />
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </section>
</template>

<script>
import IconsSocial from "@/components/IconsSocial.vue";


export default {
  name: "AppFooter",
  components: {
    IconsSocial
  },
}
</script>

<style scoped>
a {
  text-decoration: none;
  cursor: pointer;
}
p{
  color: black;
  margin-left: 21%;
  width: 61%;
}
.footer-dark {
  padding: 25px 0;
  color: #f0f9ff;
}
.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 2;
  font-size: 15px;
  margin-bottom: 0;
  text-align: left;
  color: black;
}
.footer-dark ul a {
  color: inherit !important;
  text-decoration: none;
}
a:not(.md-button) {
    text-decoration: none!important;
}
.footer-dark ul a:hover {
  opacity: 0.8;
}
.img2 img{
  max-width: 100%;
  height: 40px;
}
.text-cm{
  font-size: 18px;
  align-self: center;
  color: black!important;
}
@media (max-width: 767px) {
  .footer-dark ul {
    margin-top: 30px;
    text-align: center;
    padding-bottom: 20px;
  }
  .footer-dark .item.lastItem img {
    width: 80% !important;
  }
}
.footer-dark .item.text {
  margin-bottom: 36px;
}
@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}
.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}
.footer-dark .item.lastItem img {
  width: 40%;
}
@media (max-width: 991px) {
  .footer-dark .item.lastItem {
    text-align: center;
    margin-top: 30px;
  }
  .footer-dark .icons {
    justify-content: space-around;
    position: relative;
  }
  .footer-dark .item.lastItem img {
  width: 100%;
  }
  p{
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .footer-dark .icons {
    justify-content: center;
    right: 0;
  }
}
.footer-dark .item.lastItem > a:hover {
  opacity: 0.9;
}
.footer-dark .logo img {
  max-width: 100%;
  height: 85px;
}
.footer-dark .icons span:hover {
  opacity: 0.8;
}
</style>