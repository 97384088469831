<template>
    <!-- Slides -->
    <vueper-slides style="padding: 0px 50px;" fixed-height="26px" class="no-shadow cursor-pointer mb-0" :gap=1 :bullets="false" :visible-slides="5" slide-multiple :dragging-distance="70" :touchable="false" :breakpoints="{
        1550: { visibleSlides: 3, slideMultiple: 3 },
        1300: { visibleSlides: 2, slideMultiple: 2 },
        670: { visibleSlides: 2, slideMultiple: 2 },
        570: { visibleSlides: 1, slideMultiple: 1 },
        }"
    >
    <!-- Custom arrows -->
    <template v-slot:arrow-left>
        <span class="icon-arrow-category-left"></span>
    </template>
    <template v-slot:arrow-right>
        <span class="icon-arrow-category-right"></span>
    </template>
    <!-- Content -->
    <vueper-slide v-for="date in dates" :key="date">
        <template v-slot:content>
            <div class="d-flex justify-content-center align-items-center">
                <p @click="dateClicked(date)" :class="{'selected': isSelected(date)}">{{changeDate(date)}}</p>
            </div>
        </template>
    </vueper-slide>
    </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
var moment = require('moment');

export default {
    components:{VueperSlides, VueperSlide},
    data(){
        return{
            selected: null
        }
    },
    mounted: function()
    {
        moment.locale(this.$i18n.locale)

        // Get categories
        const itemsPerPage = 999;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "Name";
        var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
        input.visible = true;

        this.getListDates(input);
    },
     methods:
    {
        ...mapActions({
            getListDates: 'events/getDate',
        }),
        dateClicked: function(id)
        {
            this.selected = this.selected == id ? null : id;
            this.$emit("change", this.selected);
        },
        isSelected(id)
        {
            return this.selected == id;
        },
        changeDate: function(date)
        {
            if(!(date instanceof Date))
            {
                date = new Date(date);
            }
            return moment(date).format('MMMM.YY').toUpperCase();
        }
    },
    computed:
    {
        ...mapState({ 
            datesOutput: state => state.events.dateListOutput
        }),
        dates: function()
        {
            return this.datesOutput && this.datesOutput.data.length && this.datesOutput.data ? this.datesOutput.data : null;
        },
    }
}
</script>

<style scoped>
[class^="icon-arrow-category-right"], [class*="icon-arrow-category-left"] {
  font-size: 40px;
}
p{
    margin: 0;
    color: #E31E24;
}
@media (max-width: 1103px) {
    .vueperslides{
        padding: 0 90px!important;
    }
}
@media (max-width: 641px) {
    .vueperslides{
        padding: 0 56px!important;
    }
}
@media (max-width: 400px) {
    .vueperslides{
        padding: 0 5px!important;
    }
}
p.selected
{
    border: 1px solid #E31E24;
    border-radius: 20px;
    padding: 0 10px;
    content: 'x'
}
p.selected::after
{
    content: ' x '
}
</style>