<template>
<section id="header">
  <SideMenu @hash-requested="onHashRequested"/>
  <nav class="navbar navbar-expand-lg navbar-light background-color-primary sticky-top d-flex">
      <div class="col-1 col-xl-2 d-flex justify-content-start">
      <!-- Icon-->
        <div id="navbar-toggle" ref="hamburger" v-b-toggle.sidebar-left class="d-flex flex-column justify-content-center">
          <span id="btn-open" class="icon-menu"></span>
          <img id="btn-close" class="sizebtn" src="../assets/close.svg" alt="closeIcon"/>
        </div>
      </div>
      <div class="col-8 col-xl-8 d-flex justify-content-center">
        <router-link class="navbar-brand" to="/"><img class="img-fluid" src="../assets/CMA_marca_negativo_vertical.png" alt=""></router-link>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
              <li class="nav-item active">
                <router-link class="nav-link" :to="{ name: 'Trails'}">{{ $t("topMenu.oQueFazer")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Pois', query: { type: 2 }}">{{ $t("topMenu.oQueVer")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Pois', query: { type: 3 }}">{{ $t("topMenu.ondeComer")}}</router-link>
              </li>
              <li class="nav-item">
                <router-link class="nav-link" :to="{ name: 'Pois', query: { type: 4 }}">{{ $t("topMenu.ondeFicar")}}</router-link>
              </li>
          </ul>
        </div>
      </div>
      <div class="col-3 col-xl-2  d-flex justify-content-end">
        <div class="d-flex justify-content-end">
            <li class="nav-item2">
                <div class="search_field" id="search">
                  <input class="inp" :placeholder="$t('topMenu.oQueProcura')" v-model="searchQuery" v-on:keyup.enter="searchClicked"/>
                  <button class="search-button" type="submit" @click="searchClicked">
                      <span class="icon-search"></span>
                  </button>
                </div>
                <div class="dropdown" id="dropdown">
                <button class="btn .bg-white dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">{{ $i18n.locale.toUpperCase() }}</button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <li>
                    <a href="#" @click.prevent="setLocale('pt')" ref="account" class="dropdown-item">
                      <span class="ml-2 h-8 w-8">{{ $t("dropDown.portuguese")}}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="setLocale('en')" class="dropdown-item">
                      <span class="ml-2">{{ $t("dropDown.english")}}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="setLocale('fr')" class="dropdown-item">
                      <span class="ml-2">{{ $t("dropDown.french")}}</span>
                    </a>
                  </li>
                  <li>
                    <a href="#" @click.prevent="setLocale('es')" class="dropdown-item">
                      <span class="ml-2">{{ $t("dropDown.spain")}}</span>
                    </a>
                  </li>
                </ul>
              </div>
            </li>
        </div>
      </div>
  </nav>
</section>        
</template>

<script>
import  SideMenu  from "@/components/SideMenu.vue";

export default {
  name: "AppHeader",
  components: {SideMenu},
  data() {
    return {
      searchQuery: ''
    };
  },
  methods: {
    onHashRequested: function(hash)
    {
      this.$emit('hash-requested', hash);
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      localStorage.setItem('locale', locale);
      this.$router.push("/");
      this.$router.go(0);
    },
    searchClicked()
    {
      if(this.searchQuery)
      {
        this.$router.push({name: 'SearchResults', query: { query: this.searchQuery } });
        this.searchQuery = '';
        this.searching = false;
      }
      else
      {
        this.searching = !this.searching;
      }
    }
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
.sticky-top{
  width: 100%;
}
.sizebtn{
  width: 20px;
}
#btn-close
{
  position: absolute;
  top: 35%;
  left: 1%;
}
.collapsed #btn-close,
.not-collapsed #btn-open
{
  display: none;
}
.navbar{
  padding: 0!important;
  border-bottom: 4px solid white;
  max-height: 70px;
  box-shadow: 0 0 10px rgba(0,0,0,0.75);
}
a{
color: white!important;    
}
a:hover{
  color: rgba(255, 255, 255, 0.8)!important;
}
.navbar-brand img {
max-width: 150px;
}
.navbar-brand  {
width: 20%;
}
.navbar-nav {
flex-grow: 1;
}
.nav-item {
  margin: 0 auto;
}
.nav-item2 {
position: relative;
white-space: nowrap;
margin: auto 0;
list-style: none;
margin-right: 10px;
}
.search_field{
  margin-top: -25px;
}
.search-button {
background: transparent;
border: none;
outline: none;
margin-left: -45px;
}
.search-button span {
position: relative;
top: 15px;
left: 10px;
}
.inp {
border: none;
background-color: transparent !important;
border-bottom: 1px solid white;
color: white !important;
outline: none;
font-size: 0.8rem;
}
.btn:hover {
    color: white!important;
}
.btn:focus {
    box-shadow: none!important;
}
::placeholder {
color: white;
opacity: 1;
}
[placeholder]:focus::-webkit-input-placeholder {
transition: text-indent 0.4s 0.4s ease;
text-indent: -100%;
opacity: 1;
}
.dropdown-menu {
left: -13%!important;
}
.dropdown-menu a {
color: black !important;
margin: 0 10px !important;
padding: 0px !important;
text-decoration: none!important;
}
.dropdown > button {
color: white;
float: right;
}
.dropdown ul li {
width: 145px;
}
.dropdown ul a:focus {
color: #fff!important;
background: #A10D0D;
border-color: #A10D0D;
}

@media (max-width: 700px) {
  .navbar-brand  {
    display: none;
}
}
@media (max-width: 420px) {
  #btn-close
    {
      left: 3%;
    }
}
</style>