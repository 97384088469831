<template>
   <div class="container-fluid" id="slider">
    <!-- Title -->
    <div class="d-flex justify-content-end">
      <div v-if="title" class="col-6 text-center title" :class="{'col-12': !loading && !trails.length}">{{title}}</div>
      <div v-if="loading || (!loading && trails.length)" class="show-more-container d-flex cursor-pointer align-items-center col-6 col-lg-3 pr-5 pr-lg-0">
        <span @click="sendClicked" class="icon-show-more"></span>
        <span @click="sendClicked">{{ $t("verTodos")}}</span>
      </div>
    </div>
    <!-- Slides -->
    <vueper-slides v-if="loading || (!loading && trails.length)" style="padding: 0px 223px" fixed-height="275px" :touchable="true" class="no-shadow mt-4" :gap=1 :bullets="false" :visible-slides="5" slide-multiple autoplay :pause-on-hover="true" :dragging-distance="70" :breakpoints="{
        1550: { visibleSlides: 4, slideMultiple: 4 },
        1300: { visibleSlides: 3, slideMultiple: 3 },
        1100: { visibleSlides: 2, slideMultiple: 2 },
        670: { visibleSlides: 1, slideMultiple: 1 },
      }"
    >
      <!-- Custom arrows -->
      <template v-slot:arrow-left>
        <span class="icon-arrow-left"></span>
      </template>
      <template v-slot:arrow-right>
        <span class="icon-arrow-right"></span>
      </template>
      <!-- Content -->
      <vueper-slide class="slider-thumb" v-for="(block,index) in blocks" :key="index" >
        <template v-if="loading" v-slot:content>
          <SkeletonLoading class="w-100 h-100"></SkeletonLoading>
        </template>
        <template v-else v-slot:content>
          <TrailThumb :trailContent="block"/>
        </template>
      </vueper-slide>
    </vueper-slides>
    <NoResultsPlaceholder v-else></NoResultsPlaceholder>
  </div>
</template>

<script>
import TrailThumb from "@/components/TrailThumb.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import { mapState, mapActions } from "vuex";
import InputGetList from "@/classes/InputGetList";


export default {
  components: { VueperSlides, VueperSlide, TrailThumb, SkeletonLoading, NoResultsPlaceholder},
  props:{
    title: String,
    searchQuery: String
  },
  data() {
      return {
        trails: []
    }
  },
  methods:{
    ...mapActions({
      getTrailslist: 'trails/getList',
    }),
    sendClicked () {
      this.$router.push({name: 'Trails'});
    },
    getList(){
      // GET list of pois
      const itemsPerPage = 10;
      const currentPage = 1;
      const searchText = this.searchQuery;
      const sorter = 2; // desc
      const propertyName = "id";
      
      //Make request
      var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
      
      // List specific parameters
      input.visible= true;

      this.getTrailslist(input);
    }
  },
  computed:
  {
    ...mapState('trails', ['listOutput']),

    loading: function()
    {
      return this.listOutput == null;
    },
    blocks: function()
    {
      return this.loading ? new Array(5): this.trails;
    },
  },
  mounted()
  {
    this.getList();
  },
  watch:
  {
    listOutput: function()
    {
      this.trails = this.listOutput  && this.listOutput.data && this.listOutput.data.trails ? this.listOutput.data.trails : []
    },
    searchQuery: function()
    {
      this.getList();
    }
  }
}
</script>
<style scoped>
[class^="icon-arrow-left"], [class*="icon-arrow-right"] {
  font-size: 150px;
}
[class*="icon-show-more"] {
  font-size: 60px;
}
.icon-arrow-left{
  margin-left: -47px;
}
.icon-arrow-right{
  margin-right: -47px;
}
.title{
  font-size: 30px;
}
a{
  text-decoration: none;
  color: black;
}
a:hover{
  color: black;
}
.slider-thumb{
    cursor: pointer;
}
@media (max-width: 991px)
{
  .show-more-container
  {
    justify-content: center;
  }
}
@media (max-width: 1030px) {
  .vueperslides{
    padding: 0 90px!important;
  }
  .show-more{
    margin: 20px auto;
    justify-content: center;
  }
}
@media (max-width: 600px) {
  .vueperslides{
    padding: 0 35px !important;
  }
}
@media (max-width: 430px) {
  .vueperslides{
    padding: 0 56px !important;
  }
  .icon-arrow-left{
  margin-left: -80px;
  }
  .icon-arrow-right{
  margin-right: -80px;
  }
  .title{
  font-size: 20px;
  }
}

</style>