<template>
  <div v-if="searchQuery">
    <div class="container-fluid">
      <div class="row my-5 justify-content-between align-items-center">
          <div class="col-6 col-md-4 col-lg-3 title title-shape">
              <h2 class="align-self-center">{{$t("search")}}</h2>
          </div>
          <div id="search-query-container" class="col-12 col-md-6 col-lg-8 categories align-items-center">
            {{searchQuery}}
          </div>
          <div id="go-back-container" class="col-6 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
              <a @click="$router.back()" class="nav-link"><h5 class="color-primary cursor-pointer">{{ $t("voltar")}}</h5></a>
          </div>
      </div>
    </div>
    <TrailsSlider :searchQuery="searchQuery" :title="$t('oQueFazer').toUpperCase()" class="mt-5 mb-5"/> 
    <PoisSlider :searchQuery="searchQuery" :title="$t('oQueVer').toUpperCase()" :type="2" class="mt-5 mb-5"/> 
    <PoisSlider :searchQuery="searchQuery" :title="$t('ondeComer').toUpperCase()" :type="3" class="mt-5 mb-5"/>
    <PoisSlider :searchQuery="searchQuery" :title="$t('ondeFicar').toUpperCase()" :type="4" class="mt-5 mb-5"/> 
    <EventSlider :searchQuery="searchQuery" :title="$t('agenda').toUpperCase()" class="mt-5 mb-5"/>
  </div>
</template>


<script>
import PoisSlider from "@/components/PoisSlider.vue";
import TrailsSlider from "@/components/TrailsSlider.vue";
import EventSlider from "@/components/EventSlider.vue";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
    metaInfo () {
      return { 
        title: this.searchQuery,
        titleTemplate: '%s - Alcobaça', 
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: 
        [
          {name: 'description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
          {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
          // Twitter Card
          {name: 'twitter:card', content: "summary"},
          {name: 'twitter:title', content:  this.searchQuery},
          {name: 'twitter:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
          {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
          //Facebook OpenGraph
          {property: 'og:title', content:  this.searchQuery},
          {property: 'og:site_name', content: 'Alcobaça'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
          {property: 'og:description', content: UTILS_HELPER.htmlToText(this.$t("intro"))},
        ], 
        link: [
          {rel: 'canonical', href: process.env.VUE_APP_URL},
        ]
      } 
    }, 
    components: {
      PoisSlider,
      TrailsSlider,
      EventSlider
    },
    data() {
      return {
        searchQuery: '',
        selectedSearch: null,
      }
    },
    methods: 
    {
      initializePage () 
      {
        if(!this.$route.query.query)
        {
            this.$router.push({ path: '/' });
        }
        else
        {
            this.searchQuery = this.$route.query.query;
        }
      },
    },
    mounted() {
      this.initializePage();
    },
    watch: {
      $route(to) {
        if (to.name == "SearchResults") {
          this.initializePage();
        }
      },
    },
}
</script>

<style scoped>
/* Title */
.title-shape{
  height: 55px;
  border-top:2px solid #E31E24;
  border-right:2px solid #E31E24;
  border-bottom:2px solid #E31E24;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;
}

/* Header bar */
@media (max-width: 767px) /* Two columns */
{
  #search-query-container
  {
    order: 3;
    margin: 2rem 0;
  }

  #go-back-container
  {
    order: 2;
  }
}
</style>