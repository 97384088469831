<template>
      <div class="row d-flex justify-content-center">
        <div class="col-3">
            <a target="_blank" href="https://www.facebook.com/municipioalcobaca" rel="noopener" ><span class="icon-face"></span></a>
        </div>
         <!-- <div class="col-3">
              <a target="_blank" href="" rel="noopener"><span class="icon-insta"></span></a>
          </div> -->
        <div class="col-3">
            <a target="_blank" href="https://www.youtube.com/channel/UC7k_s1x7nE3cp9meCegOLbA" rel="noopener"><span class="icon-youtube"></span></a>
        </div>
        <div class="col-3">
            <a target="_blank" href="https://www.tripadvisor.com/Tourism-g230083-Alcobaca_Leiria_District_Central_Portugal-Vacations.html" rel="noopener"><span class="icon-ico"></span></a>
        </div> 
      </div>
</template>

<script>
export default {

}
</script>

<style scoped>
[class^="icon-"], [class*=" icon-"] {
    font-size: 80px;
    color: white;
}
a{
    text-decoration: none;
}

</style>