<template>
    <section>
        <div class="background">
            <div class="container">
                <div class="row col-12 form">
                    <!-- Talk with us -->
                    <div class="col-12 col-lg-6" id="support">
                        <h2 class="font-weight-bold mt-3">{{ $t('contact.faleConnosco') }}</h2>
                            <form>
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group mt-4">
                                            <input type="text" class="form-control" :placeholder="$t('contact.nome')" v-model="firstName" :class="{ 'is-invalid': submitted && hasError('FirstName') }">
                                            <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                                                <span v-if="!$v.firstName.required">{{$t("invalidFirstName")}}</span>
                                                <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group mt-4">
                                            <input type="text" class="form-control" :placeholder="$t('contact.apelido')" v-model="lastName" :class="{ 'is-invalid': submitted && hasError('LastName') }">
                                            <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
                                                <span v-if="!$v.lastName.required">{{$t("invalidLastName")}}</span>
                                                <span v-if="errors.LastName">{{errors.LastName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mt-4">
                                            <input type="email" class="form-control" id="email" :placeholder="$t('contact.email')" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }">
                                            <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                                                <span v-if="!$v.email.required">{{$t("invalidEmail")}}</span>
                                                <span v-if="!$v.email.email">{{$t("login.invEmail")}}</span>
                                                <span v-if="errors.Email">{{errors.Email}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="form-group mt-4">
                                            <textarea class="form-control" id="Textarea1" rows="3" :placeholder="$t('contact.mensagem')" v-model="content" :class="{ 'is-invalid': submitted && hasError('Content') }"></textarea>
                                            <div v-if="submitted && hasError('Content')" class="invalid-feedback">
                                                <span v-if="!$v.content.required">{{$t("invalidContent")}}</span>
                                                <span v-if="errors.Content">{{errors.Content}}</span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                                <div :class="{'disabled': sent}" class="col mt-3 btn btn-button w-100" @click="submitClicked">
                                    <span v-if="!loading" class="text-uppercase">{{ $t("submeter")}}</span>
                                    <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    <span v-if="loading" class="sr-only">{{$t("loadingMessage")}}</span>
                                </div>
                                <!-- Result message -->
                                <p v-if="sent" id="success-message">{{$t("formSent")}}</p>
                            </form>
                            <div class="row">
                                <div class="mt-5">
                                    <img class="img-fluid" src="../assets/CMA_marca_positivo_horizontal.png" alt="logo" style="max-width: 150px;">
                                </div>
                                <p class="mt-5">Posto de Turismo de Alcobaça</p>
                                <div class="mt-2">
                                    <span class="d-block">Rua Araújo Guimarães</span>
                                    <span class="d-block">2460-025 Alcobaça</span>
                                    <span class="d-block">Tel: +351 924 032 615 {{$t("contact.callTo")}}</span>
                                    <span class="d-block">E-mail: turismo@cm-alcobaça.pt</span>
                                </div>
                            </div>
                    </div>
                    <!-- Map -->
                    <div id="directions" class="col-12 col-lg-6">
                        <h2 class="font-weight-bold mt-3">{{ $t('contact.comoChegar') }}</h2>
                        <iframe class="mt-4" width="100%" height="670" :src="MAP_URL" title="Main content" name="contents"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import { UTILS_HELPER } from "@/helpers/utils.js";

const MAP_URL = "https://maps.google.com/maps?q=39.5519199,-8.9763396&hl=es;z=14&output=embed";
const MESSAGE_TYPE = "General";

export default {
    data() {
        return {
            // Message
            firstName: '',
            lastName: '',
            email: '',
            content: '',
            // Others
            submitted: false,
            loading: false,
            sent: false,
            // Consts
            MAP_URL: MAP_URL,
        }
    },
    validations() {
        return {
            firstName: { required },
            lastName: { required },
            email: { required, email },
            content: { required }
        }
    },
    computed:
    {
        ...mapState('messages', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        }
    },
    methods: 
    {
        ...mapActions("messages", ["sendMessage"]),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        submitClicked: function()
        {
            if(!this.sent)
            {
                this.clearAlert();
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                this.loading = true;
                const {firstName, lastName, email, content} = this;
                const type = MESSAGE_TYPE;

                this.sendMessage({firstName, lastName, email, content, type});
            }
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        }
    },
    watch:
    {
        processTask: function(val)
        {
            if(val != null)
            {
                this.loading = false;

                if(val)
                {
                    this.sent = true;
                }
                else if(val == false)
                {
                    this.$emit("error-alert-requested");
                }
            }
        }
    }
}
</script>

<style scoped>
section{
    border-top: 2px solid #A10D0D;
}
.btn-button{
    background-color: #A10D0D;
    color: white;
}
.btn-button.disabled
{
    opacity: 0.5;
}
.background{
    background-image: url(../assets/support/background.jpg);
    background-size: cover;
}
.form{
    padding: 30px 0;
    margin: 0 auto;
}
</style>