<template>
    <div>
        <div class="img-hero">
          <vueper-slides
            v-if="heros.length"
            :bullets="false" 
            fixed-height="100vh" 
            class="img-slide" 
            :touchable="false"
          >
            <vueper-slide v-for="(hero, i) in heros" :key="i">
                <template v-slot:content>
                  <div class="vueperslides__track-inner">
                    <div class="vueperslide" :style="{backgroundImage: 'url(' + hero.path + ')',  backgroundPosition: hero.status == 'morning' ? '80%' : '50%' }"/>     
                  </div>
                </template>
            </vueper-slide>
          </vueper-slides>
          <div class="shaper">
              <div class="moving-mouse-holder">
                  <div class="mouse position-absolute">
                      <div class="mouse-button"></div>
                  </div>
                  <div class="text-scroll position-absolute">
                      {{$t("scroll")}}
                  </div>
              </div>
          </div>
          <div class="col-12 text-center text-description mt-5 mt-md-0">
              <p v-html="$t('intro')"></p> 
          </div>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  components: {
    VueperSlides, VueperSlide
  },
  computed: {
    heros() {
      // Check the current hour
      const currentHour = new Date().getHours();
      // Define an array to hold the image object
      const images = [];
      // Push the appropriate image object to the array based on the current hour
      if (currentHour >= 6 && currentHour < 19) {
        images.push({ status: 'morning' , path: require('@/assets/hero/hero03.jpg') }); // Morning
      } else {
        images.push({ status: 'evening' ,path: require('@/assets/hero/hero04.jpg') }); // evening
      }
      // Return the array containing the image object
      return images;
    }
  }
}
</script>

<style scoped>
.img-hero{
  position: relative;
}
.img-hero img{
  width: 100%;
  height: 100vh;
  object-fit: cover;
}
.shaper {
  position: absolute;
  -webkit-clip-path: ellipse(55% 19% at 50% 100%);
  clip-path: ellipse(55% 19% at 50% 100%);
  height: 100vh;
  background-color: white;
  box-sizing: border-box;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moving-mouse-holder .mouse {
  width: 26px;
  height: 40px;
  position: relative;
  bottom: 12%;
  border-radius: 18px;
  border: 2px solid #000000;
}
.moving-mouse-holder .mouse-button {
  background-color: #000000;
  width: 4px;
  height: 10px;
  border-radius: 2px;
  position: absolute;
  top: 10px;
  left: 50%;
  margin: 0 0 0 -2px;
  animation: mouse-scroll 1s infinite alternate;
}
.moving-mouse-holder .text-scroll {
  margin-left: -10px;
  color: black;
  font-size: 15px;
  line-height: 1em;
  bottom: 9%;
}
@keyframes mouse-scroll {
  to {
    transform: translate(0, 6px) scale(1, 0.8);
  }
}
.text-description{
  width: 50%;
  margin: 0 auto;
  padding: 0 20px;
}
@media (max-width: 963px) {
.text-description{
  width: 90%;
  text-align: justify!important;
}
  
}
@media (max-width: 500px) {
.shaper {
  position: unset;
  -webkit-clip-path: unset;
  clip-path: unset;
  height: unset;
  background-color: unset;
  box-sizing: unset;
  z-index: unset;
  width: unset;
  display: unset;
}
.moving-mouse-holder .mouse {
  width: unset;
  height: unset;
  position: unset;
  border-radius: unset;
  border: unset;
}
.moving-mouse-holder .mouse-button {
  background-color: unset;
  width: unset;
  height: unset;
  border-radius: unset;
  position: unset;
  margin: unset;
  animation:unset;
}
.moving-mouse-holder .text-scroll {
  margin-left: -unset;
  color: unset;
  font-size: unset;
  line-height: unset;
  bottom: unset;
  display: none;
}
  
}
</style>