<template>
  <div>
    <div v-if="headerImage" class="col-12 img">
        <img class="w-100" :src="headerImage" alt="">
    </div>
    <div class="container">
      <div class="row">
        <div id="nav-buttons" class="d-flex align-items-center">
          <a @click="$router.back()" class="nav-link color-primary cursor-pointer">{{ $t("voltar")}}</a><span class="h-separator align-items-center color-primary"> | </span><router-link :to="{ name: 'Trails'}" class="nav-link color-primary cursor-pointer">{{ $t("verTodos")}}</router-link> 
        </div>
        <div class="col-12">
          <div class="d-flex align-items-center title-section flex-wrap">
            <h6>{{categoryName.toUpperCase()}} | {{$tc("dayCount", totalDays, {days: totalDays})}}<span v-if="totalDays > 1"> - {{$tc("nightCount", totalDays - 1, {nights: totalDays - 1})}} | </span></h6>
            <h3 class="fw-bold">{{detailTrails.title}}</h3>
          </div>
          <div class="description mt-4">
              <p v-html="detailTrails.description"/>        
          </div>
        </div>
     
      </div>
    </div>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
  props: {
    detailTrails: Object
  },
  computed: 
  {
    headerImage: function()
    {
      return this.detailTrails && this.detailTrails.image ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.detailTrails.image]) : [require("../assets/poiDefaultHeader.png")];
    },
    getImage: function() {
      return this.detailTrails && this.detailTrails.this.image  ? this.detailTrails.this.image : [];
    },
    categoryName: function() {
      return this.detailTrails && this.detailTrails.categories[0] && this.detailTrails.categories.length && this.detailTrails.categories[0].name ? this.detailTrails.categories[0].name : [];
    },
    totalDays: function() {
      return this.detailTrails && this.detailTrails.days ? this.detailTrails.days.length : 1;
    }
  }
};
</script>

<style scoped>
.back{
  cursor: pointer;
}
h6, h3{
  display: inline;
  margin: 0;
}
.title-section
{
  gap: 4px;
}
@media (max-width: 991px) {
  .title-section
  {
    margin-top: 1rem;
  }
}
@media (min-width: 992px) {
  .description
  {
    margin-top: 3rem;
  }
}
a,
.h-separator
{
  font-size: 22px;
}

@media (min-width: 992px) {
  .container
  {
    margin-top: 3rem;
  }

  #nav-buttons
  {
    justify-content: flex-end;
  }
}

@media (max-width: 991px)
{
  .container
  {
    margin-top: 1rem;
  }

  #nav-buttons
  {
    justify-content: center;
    margin-bottom: 0;
  }
}

	
.text-responsive {
  font-size: calc(100% + 1vw + 1vh);
}
</style>
