<template>
    <!-- Slides -->
    <vueper-slides style="padding: 0px 50px;" fixed-height="26px" class="no-shadow cursor-pointer mb-0" :gap=1 :bullets="false" :visible-slides="5" slide-multiple :dragging-distance="70" :touchable="false" :breakpoints="{
        1550: { visibleSlides: 3, slideMultiple: 3 },
        1300: { visibleSlides: 2, slideMultiple: 2 },
        670: { visibleSlides: 2, slideMultiple: 2 },
        570: { visibleSlides: 1, slideMultiple: 1 },
        }"
    >
    <!-- Custom arrows -->
    <template v-slot:arrow-left>
        <span class="icon-arrow-category-left"></span>
    </template>
    <template v-slot:arrow-right>
        <span class="icon-arrow-category-right"></span>
    </template>
    <!-- Content -->
    <vueper-slide v-for="type in types" :key="type.key">
        <template class="d-flex" v-slot:content>
            <div class="d-flex justify-content-center align-items-center">
                <p @click="documentTypeClicked(type.key)" :class="{'selected': isSelected(type.key)}">{{type.label}}</p>
            </div>
        </template>
    </vueper-slide>
    </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    components:{VueperSlides, VueperSlide},
    data (){
        return {
            selected: null,
            types: [{label: this.$t("maps"), key: "Maps"}, {label: this.$t("routes"), key: "Routes"}, {label: this.$t("touristicPrograms"), key: "TouristicPrograms"}, {label: this.$t("others"), key: "Others"}],
        }
    },
    methods:
    {
        documentTypeClicked: function(key)
        {
            this.selected = this.selected == key ? null : key;
            this.$emit("change", this.selected);
        },
        isSelected(key)
        {
            return this.selected == key;
        }
    },
}
</script>

<style scoped>
[class^="icon-arrow-category-left"], [class*="icon-arrow-category-right"] {
  font-size: 40px;
}
p{
    margin: 0;
    color: #E31E24;
}
@media (max-width: 1103px) {
    .vueperslides{
        padding: 0 90px!important;
    }
}
@media (max-width: 641px) {
    .vueperslides{
        padding: 0 56px!important;
    }
}
@media (max-width: 400px) {
    .vueperslides{
        padding: 0 5px!important;
    }
}
p.selected
{
    border: 1px solid #E31E24;
    border-radius: 20px;
    padding: 0 10px;
    content: 'x'
}
p.selected::after
{
    content: ' x '
}
</style>