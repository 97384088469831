<template>
  <div v-if="poiContent" @click="sendClicked" class="vueperslide__content-wrapper cursor-pointer">
    <div class="position-relative content" :style="{'background-image': 'url(' + imageUrl + ')'}">
      <div class="overlay position-absolute d-flex align-items-center">
          <div class="card-pois flex-grow-1">
            <div class="category-name text-white mt-1">
                <span>{{categoryName.toUpperCase()}}</span>
            </div>
          </div>
      </div>
      <div class="overlay_2 position-absolute">
        <div class="d-flex flex-column">
          <div class="poi-name text-white mt-2">
            <span>{{poiContent.name.toUpperCase()}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { API_HELPER } from "@/helpers/api.js";

export default {
 props: {
    poiContent: Object
  },
  computed: {
    imageUrl: function() {
      return this.poiContent.files && this.poiContent.files.length && this.poiContent.files[0].path ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.poiContent.files[0].path]) : [require("../assets/poiDefault.jpg")];
    },
    categoryName: function() {
      return this.poiContent.categories && this.poiContent.categories.length && this.poiContent.categories[0].name ? this.poiContent.categories[0].name : '';
    }
  },
  methods:{
    sendClicked () {
      this.$router.push({name: "PoisDetail", params: {poiContent: this.poiContent}, query: {id: this.poiContent.id} });
    },
  },
}
</script>

<style scoped>
/* In order to guarantee squares, there is a container */
.content
{
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 20px;
}

/* Remaining structure */
.vueperslide__content-wrapper
{
  max-width: 275px;
  max-height: 275px;
}
.category-name {
  font-size: 17px;
}
.overlay {
  height: 100%;
  width: 100%;
  background-color: hsla(0, 85%, 34%, 0.4);
  max-height: 275px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.vueperslide .overlay
{
  visibility: hidden;
  transition: 1s ease;
  background-color: hsla(1, 63%, 36%, 0);
  max-height: 45px;
  top: 0;
}
.vueperslide .overlay * {
  opacity: 0;
}

.overlay_2 {
  height: 100%;
  width: 100%;
  background-color:hsla(0, 0%, 0%, 0.5);
  max-height: 275px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.vueperslide .overlay_2
{
  visibility: hidden;
  transition: 1s ease;
  background-color:hsla(1, 63%, 36%, 0);
  max-height: 80px;
  bottom: 0;
}
.vueperslide .overlay_2 * {
  opacity: 0;
}
.overlay_2 .poi-name{
  width: 100%;
  text-align: start;
  font-size: 15px;
  padding: 0 10px;
}
/* Show on hover and touch devices */
.vueperslide .vueperslide__content-wrapper:hover .overlay *,
.vueperslide .vueperslide__content-wrapper:hover .overlay_2 * {
  opacity: 1;
}
.vueperslide__content-wrapper:hover .overlay  {
  visibility: initial;
  background-color: hsla(0, 85%, 34%, 0.4);
}
.vueperslide__content-wrapper:hover .overlay_2  {
  visibility: initial;
  background-color: hsla(0, 0%, 0%, 0.5);
}

@media (hover: none) {
  .vueperslide .vueperslide__content-wrapper .overlay *,
  .vueperslide .vueperslide__content-wrapper .overlay_2 * {
    opacity: 1;
  }
  .vueperslide__content-wrapper .overlay  {
    visibility: initial;
    background-color: hsla(0, 85%, 34%, 0.4);
  }
  .vueperslide__content-wrapper .overlay_2  {
    visibility: initial;
    background-color: hsla(0, 0%, 0%, 0.5);
  }
}
@media (max-width: 991px) {
  .category-name{
    font-size: 15px;
  }
}
@media (max-width: 375px) {
  .overlay_2 .poi-name{
    font-size: 13px;
  }
}
</style>