<template>
    <div class="container flex-wrap"> 
        <div v-if="featuredImage" class="col-12 col-md-4 position-relative cursor-pointer h-100">
            <img class="w-100" @click="showGallery = true" :src="featuredImage" alt=""/>
            <div class="gallery-more position-absolute d-flex align-items-end justify-content-end" @click="showGallery = true" v-if="plusNumber"><span>+ {{plusNumber}}</span></div>
        </div>
        <div class="col-12 col-md-8 flex-grow-1 description-container">
            <div class="fw-bold">{{trailDay.title}}</div>
            <div v-html="trailDay.description"/>
        </div>
        <hr class="col-12"/> 
        <!-- Gallery pop up -->
        <FullScreenImage v-if="showGallery" @closeRequest="showGallery = false" :gallery="gallery"></FullScreenImage>
    </div>

</template>

<script>
import FullScreenImage from "@/components/FullScreenImage.vue";
import { API_HELPER } from "@/helpers/api.js";

export default {
    components: { FullScreenImage },
    props:{
        trailDay: Object
    },
    data() {
        return {
            showGallery: false
        }
    },
    computed:{
        featuredImage: function() 
        {
            return this.trailDay && this.trailDay.files.length && this.trailDay.files[0].path ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.trailDay.files[0].path]) : [require("../assets/poiDefault.jpg")];
        },    
        gallery: function()
        {
            return this.trailDay && this.trailDay.files && this.trailDay.files.length ? this.trailDay.files.map(file=> API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, file.path])) : [require("../assets/poiDefault.jpg")];
        },
        plusNumber: function()
        {
            return this.trailDay && this.trailDay.files && this.trailDay.files.length ? this.trailDay.files.length - 1 : 0;
        }
    },
}
</script>

<style scoped>
.gallery-more
{
    background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    min-height: auto;
    padding: 0.5rem 1rem;
    color: white;
    right: 0;
}
img{
    height: 100%;
    object-fit: contain;
}
hr
{
    margin: 2rem 0;
    width: 100%;
    height: 2px;
    border-top: 2px dotted #A10D0D;
    background-color: transparent;
}

@media (max-width: 1199px) {
    .gallery-more
    {
        padding: 0.5rem;
        min-height: auto;
    }
}
@media (min-width: 992px) {
    .description-container
    {
        padding: 0 0 0 1rem;
    }

    .row-reverse .description-container
    {
        padding: 0 1rem 0 0;
    }
}

@media (max-width: 991px) {
    .description-container
    {
        padding: 1rem 0 0.5rem 0;
    }

    hr
    {
        margin: 0.5rem 0 2rem 0;
    }
}


</style>