<template>
<div>
    <div class="container-fluid">
      <div class="row my-5 justify-content-between align-items-center">
          <div class="col-6 col-md-4 col-lg-3 title title-shape">
              <h2 class="align-self-center">{{title}}</h2>
          </div>
          <div id="category-filter-container" class="col-12 col-lg-8 categories align-items-center">
              <CategoryFilter v-if="type" @change="onCategoriesChanged" :type="type"/>
          </div>
          <div id="go-back-container" class="col-6 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
            <a @click="$router.back()" class="nav-link"><h5 class="color-primary cursor-pointer">{{ $t("voltar")}}</h5></a>
          </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="poi-container d-flex flex-wrap mt-2 container">
      <SkeletonLoading v-for="(block, index) in (new Array(itemsPerPage))" :key="index"></SkeletonLoading>
    </div>
    <!-- Content -->
    <div v-else class="poi-container d-flex flex-wrap mt-2 container">
      <PoisThumb  class="slider-thumb" v-for="content in pois" :key="content.id" :poiContent="content"/>
      <NoResultsPlaceholder v-if="!pois.length"></NoResultsPlaceholder>
    </div>
    <div v-show="!loading && canLoadMore" class="see">
      <span @click="loadMore" class="icon-show-more cursor-pointer d-block text-center mb-2"></span>
      <p @click="loadMore" class="d-block text-center">{{$t("carregarMais")}}</p>
    </div>
</div>
</template>

<script>
import PoisThumb from "@/components/PoisThumb.vue";
import CategoryFilter from "@/components/CategoryFilter.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import router from '@/router';
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

const DEFAULT_ITEMS_PER_PAGE = 8;

export default {
  metaInfo () {
    var result =
    { 
        title: this.title ? `${UTILS_HELPER.capitalizeFirstLetter(this.title)} - Alcobaça` : "Alcobaça", 
        htmlAttrs: {
          lang: this.$i18n.locale,
        },
        meta: 
        [
          {name: 'description', content: this.title ? UTILS_HELPER.capitalizeFirstLetter(this.title) + this.$t("vueRoutes.poisDescriptionSufix") : this.$t("intro")},
          {name: 'keywords', content: this.categories && this.categories.length ? this.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},
          // Twitter Card
          {name: 'twitter:card', content: "summary"},
          {name: 'twitter:title', content: this.title ? `${UTILS_HELPER.capitalizeFirstLetter(this.title)} - Alcobaça` : "Alcobaça"},
          {name: 'twitter:description', content: this.title ? UTILS_HELPER.capitalizeFirstLetter(this.title) + this.$t("vueRoutes.poisDescriptionSufix") : this.$t("intro")},
          {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
          //Facebook OpenGraph
          {property: 'og:title', content: this.title ? `${UTILS_HELPER.capitalizeFirstLetter(this.title)} - Alcobaça` : "Alcobaça"},
          {property: 'og:site_name', content: 'Alcobaça'},
          {property: 'og:type', content: 'website'},
          {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
          {property: 'og:description', content: this.title ? UTILS_HELPER.capitalizeFirstLetter(this.title) + this.$t("vueRoutes.poisDescriptionSufix") : this.$t("intro")},
        ]
    } 

    if(this.$route.query.type)
    {
        result.link = 
        [
            {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.pois", 'pt')])}?type=${this.$route.query.type}`},
            {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.pois", 'en')])}?type=${this.$route.query.type}`},
            {rel: 'alternate', hreflang: 'fr', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.pois", 'fr')])}?type=${this.$route.query.type}`},
            {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.pois", 'es')])}?type=${this.$route.query.type}`}
        ];
    }

    return result;
  }, 
  components:{PoisThumb, CategoryFilter, NoResultsPlaceholder, SkeletonLoading},
  data(){
      return{
        type: null,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        title: ''
      }
  },
  methods:{
    ...mapActions({
        getPois: 'pois/getList',
      }),
    updateItemsRequested: function(categories)
    {
      const currentPage = 1;
      const searchText = '';
      const sorter = 2; // desc
      const propertyName = "id";
      
      //Make request
      var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);
      input.visible = true;
      input.categoryTypes = [this.type];
      if(categories && categories.length)
      {
        input.categories = categories;
      }

      this.getPois(input);
    },
    loadMore()
    {
      if(this.canLoadMore)
      {
        this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
      }
    },
    onCategoriesChanged: function(categories)
    {
      this.updateItemsRequested(categories);
    },
    initializePage: function()
    {
      if(!this.$route.query.type )
      {
        router.push({ path: '/' });

      }
      else
      {
        this.type = parseInt(this.$route.query.type);
        switch (this.type) 
        {
          case 2:
              this.title = this.$t("oQueVer").toUpperCase();
              break;
          case 3:
              this.title = this.$t("ondeComer").toUpperCase();
              break;
          case 4:
              this.title = this.$t("ondeFicar").toUpperCase();
              break; 
          }
      }
      // Get pois
      this.updateItemsRequested();
    }
  },
  computed:{
    ...mapState({ 
      poisOutput: state => state.pois.listOutput,
      categoriesOutput: state => state.categories.listOutput
    }),
    loading: function(){
      return this.poisOutput == null || this.categoriesOutput == null;
    },
    pois: function() {
      return this.poisOutput && this.poisOutput.data && this.poisOutput.data.pois ? this.poisOutput.data.pois : [];
    },
    numPois: function(){
      return this.poisOutput && this.poisOutput.totalRecords ? this.poisOutput.totalRecords : 0;
    },
    canLoadMore: function()
    {
      return this.numPois > this.itemsPerPage;
    },
    categories: function()
    {
      return this.categoriesOutput && this.categoriesOutput.data && this.categoriesOutput.data.categories ? this.categoriesOutput.data.categories : null;
    },
  },
  mounted: function()
  {
    this.initializePage();
  },
  watch: {
    $route(to) {
      if (to.name == "Pois") {
        this.initializePage();
      }
    },
    itemsPerPage()
    {
      this.updateItemsRequested();
    }
  }
}
</script>

<style scoped>
/* Title */
.title-shape{
  height: 55px;
  border-top:2px solid #E31E24;
  border-right:2px solid #E31E24;
  border-bottom:2px solid #E31E24;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;
}
.back{
  color: #E31E24;
  cursor: pointer;
  font-size: 22px;
}
.see {
  position: relative;
  display: flex;
  justify-content: center;
}
.see > p {
  position: absolute;
  top: 60%;
  cursor: pointer;
}
[class*="icon-show-more"] {
  font-size: 60px;
}
.skeleton-box
{
  min-height: 275px;
  border-radius: 20px;
}

/* Size of poi elements: these are not done using bootstrap to have correct alignments, using gap */
.poi-container /* Four columns */
{
  gap: 4%;
}
.poi-container > .slider-thumb,
.poi-container > span
{
  width: 22%;
  margin-bottom: 4%;
}

/* Others */
>>>.overlay{
    visibility: hidden;
    transition: 1s ease;
    background-color: hsla(1, 63%, 36%, 0);
    max-height: 45px;
    top: 0;
}
>>>.overlay_2 {
    visibility: hidden;
    transition: 1s ease;
    background-color: hsla(1, 63%, 36%, 0);
    max-height: 80px;
    bottom: 0;
}
@media (max-width: 1199px) 
{
  .poi-container
  {
    gap: 5%;
    padding: 0 1rem;
  }

  .poi-container > .slider-thumb,
  .poi-container > span
  {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}
/* Header bar */
@media (max-width: 992px) /* Two columns */
{
  #category-filter-container
  {
    order: 3;
    margin: 2rem 0;
  }

  #go-back-container
  {
    order: 2;
  }
}
@media (max-width: 767px) /* Two columns */
{
  .poi-container
  {
    gap: 10%;
    padding: 0 1rem;
  }

  .poi-container > .slider-thumb,
  .poi-container > span
  {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}

@media (max-width: 575px) /* Two columns */
{
  #category-filter-container
  {
    margin-bottom: 5rem;
  }

  .poi-container
  {
    padding: 0 1rem;
  }

  .poi-container > .slider-thumb,
  .poi-container > span
  {
    width: 95%;
    max-width: 95%;
    margin-bottom: 50%;
  }
  .title h2{
   font-size: 20px;
  }
}
</style>