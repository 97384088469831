import Vue from 'vue'
import Vuex from 'vuex'
import { alert } from '@/store/alert.module';
import { pois } from '@/store/pois.module';
import { categories } from '@/store/categories.module';
import { events } from '@/store/events.module';
import { trails } from '@/store/trails.module';
import { staticPages } from '@/store/staticPages.module';
import { documents } from '@/store/documents.module';
import { messages } from '@/store/messages.module';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    alert,
    pois,
    categories,
    staticPages,
    events,
    trails,
    documents,
    messages
  }
})
