<template>
  <section>
    <LogoLoading v-if="!currentPage"/>
    <div v-else>
      <div v-if="currentPage" class="d-flex justify-content-center">
        <img :src="url + currentPage.image" class="img-fluid w-100" alt="">
      </div>
      <div v-for="tab in tabs" :key="tab.id" :title="tab.title" class="container mt-5">
        <h3 class="text-center">{{ tab.title.toUpperCase() }}</h3>
        <p class="mt-5" v-html="tab.content"></p>
      </div>
    </div>
  </section>
</template>

<script>
import router from "@/router";
import { mapState, mapActions } from 'vuex';
import InputGetList from "@/classes/InputGetList";
import LogoLoading from "@/components/LogoLoading.vue"
import { API_HELPER } from "@/helpers/api.js";
import { UTILS_HELPER } from "@/helpers/utils.js";

export default {
  metaInfo () {
    var result = 
    { 
      title: this.currentPage ? `${this.currentPage.title} - Alcobaça` : "Alcobaça", 
      htmlAttrs: {
        lang: this.$i18n.locale,
      },
      meta: 
      [
        {name: 'description', content: this.currentPage && this.currentPage.tabs && this.currentPage.tabs.length ? UTILS_HELPER.htmlToText(this.currentPage.tabs[0].content) : this.$t("intro")},
        {name: 'keywords', content: this.$t("vueRoutes.defaultKeywords")},
        // Twitter Card
        {name: 'twitter:card', content: "summary"},
        {name: 'twitter:title', content: this.currentPage ? `${this.currentPage.title} - Alcobaça` : "Alcobaça"},
        {name: 'twitter:description', content: this.currentPage && this.currentPage.tabs && this.currentPage.tabs.length ? UTILS_HELPER.htmlToText(this.currentPage.tabs[0].content) : this.$t("intro")},
        {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
        //Facebook OpenGraph
        {property: 'og:title', content: this.currentPage ? `${this.currentPage.title} - Alcobaça` : "Alcobaça"},
        {property: 'og:site_name', content: 'Alcobaça'},
        {property: 'og:type', content: 'website'},
        {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
        {property: 'og:description', content: this.currentPage && this.currentPage.tabs && this.currentPage.tabs.length ? UTILS_HELPER.htmlToText(this.currentPage.tabs[0].content) : this.$t("intro")},
      ], 
    };

    if(this.$route.query.id)
    {
      result.link = 
      [
        {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.page", 'pt')])}?id=${this.$route.query.id}`},
        {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.page", 'en')])}?id=${this.$route.query.id}`},
        {rel: 'alternate', hreflang: 'fr', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.page", 'fr')])}?id=${this.$route.query.id}`},
        {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.page", 'es')])}?id=${this.$route.query.id}`}
      ];
    }

    return result; 
  }, 
  components: {
    LogoLoading
  },
  data: function () {
    return {
      url: process.env.VUE_APP_URL_ROOT
    };
  },
  computed: {
    ...mapState('staticPages', ['listOutput']),
    pagesList: function() {
      return this.listOutput && this.listOutput.data.pages ? this.listOutput.data.pages : [];
    },
    currentPage: function() {
      return this.pagesList.length ? this.pagesList.find((page) => page.id == this.$route.query.id) : null;
    },
    tabs: function()
    {
      return this.currentPage && this.currentPage.tabs ? this.currentPage.tabs : [];
    }
  },
  methods: {
    ...mapActions('staticPages', ['getList']),

    initializePage()
    {
      if(!this.$route.query.id)
      {
        router.push({ path: '/' });
      }

      if(!this.pagesList.length)
      {
        const itemsPerPage = 10;
        const currentPage = 1;
        const searchText = '';
        const sorter = 2; // desc
        const propertyName = "id";

        var input = new InputGetList(itemsPerPage, currentPage, searchText, sorter, propertyName);
        
        // List specific parameters
        input.visible= true;
        this.getList(input);
      }
    }
  },
  mounted() {
    this.initializePage();
  },
  watch: {
    $route(to) {
      if (to.name == "StaticPage") {
        this.initializePage();
      }
    },
  },
};
</script>