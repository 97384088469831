<template>
    <div>
        <div v-if="trailContent">
            <!-- <vueper-slides class="no-shadow mb-5" :bullets="false" fixed-height:120px  :touchable="false" @slide="onSlideChanged"> -->
                <!-- Custom arrows -->
                <!-- <template v-slot:arrow-left>
                    <span class="icon-poisDetail_arrow_left"></span>
                </template>
                <template v-slot:arrow-right>
                    <span class="icon-poisDetail_arrow_right"></span>
                </template> -->
                <!-- Content -->
                <!-- <vueper-slide v-for="(trail, index) in trailContent" :key="trail.id" :id="'trail-'+index" class="trail-slide">
                    <template v-slot:content>
                        <TrailIntro :detailTrails="trailContent"/>
                        <TrailDay v-for="(day,index) in trailContent" :key="day.id" :class="{row-reverse: (index + 1) % 2 == 0}" :trailDay="trailContent" @image-clicked="imageClicked"/>
                    </template>
                </vueper-slide>
            </vueper-slides> -->
            <TrailIntro :detailTrails="trailContent"/>
            <TrailDay v-for="(day,index) in trailContent.days" :key="day.id" class="d-flex" :class="{'row-reverse': (index + 1) % 2 == 0}" :trailDay="day"/>
            <div class="col-12 d-flex justify-content-center container">
                <p>{{$t("introTrailForm")}}</p>
            </div>
            <div class="d-flex flex-wrap justify-content-between mt-4 mb-5 container">
                <div class="form-group col-12 col-lg-2 my-1">
                    <input type="text" class="form-control" :placeholder="$t('contact.nome')" v-model="firstName" :class="{ 'is-invalid': submitted && hasError('FirstName') }">
                    <div v-if="submitted && hasError('FirstName')" class="invalid-feedback">
                        <span v-if="!$v.firstName.required">{{$t("invalidFirstName")}}</span>
                        <span v-if="errors.FirstName">{{errors.FirstName}}</span>
                    </div>
                </div>
                <div class="form-group col-12 col-lg-2 my-1">
                    <input type="text" class="form-control" :placeholder="$t('contact.apelido')" v-model="lastName" :class="{ 'is-invalid': submitted && hasError('LastName') }">
                    <div v-if="submitted && hasError('LastName')" class="invalid-feedback">
                        <span v-if="!$v.lastName.required">{{$t("invalidLastName")}}</span>
                        <span v-if="errors.LastName">{{errors.LastName}}</span>
                    </div>
                </div>
                <div class="form-group col-12 col-lg-2 my-1">
                    <input type="email" class="form-control" id="email" :placeholder="$t('contact.email')" v-model="email" :class="{ 'is-invalid': submitted && hasError('Email') }">
                    <div v-if="submitted && hasError('Email')" class="invalid-feedback">
                        <span v-if="!$v.email.required">{{$t("invalidEmail")}}</span>
                        <span v-if="!$v.email.email">{{$t("login.invEmail")}}</span>
                        <span v-if="errors.Email">{{errors.Email}}</span>
                    </div>
                </div>
                <div class="form-group col-12 col-lg-2">
                    <a :class="{'disabled': sent}" class="button1 w-100" @click="submitClicked">
                        <span v-if="!loading" class="text-uppercase">{{ $t("enviar")}}</span>
                        <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span v-if="loading" class="sr-only">{{$t("loadingMessage")}}</span>
                    </a>
                </div>
                <!-- Result message -->
                <p v-if="sent" class="text-center">{{$t("formSent")}}</p>
            </div>
        </div>
        <div class="mt-5 container" v-else-if="loadingContent">
            <SkeletonLoading style="height: 20vh" class="w-100 mt-5 mb-3"></SkeletonLoading>
            <SkeletonLoading style="height: 50vh" class="w-100 mb-5"></SkeletonLoading>
        </div>
  </div>
</template>

<script>
// import { VueperSlides, VueperSlide } from 'vueperslides';
import TrailIntro from "@/components/TrailIntro.vue";
import TrailDay from "@/components/TrailDay.vue";
import 'vueperslides/dist/vueperslides.css';
import router from '@/router';
import { required, email } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import { UTILS_HELPER } from "@/helpers/utils.js";
import SkeletonLoading from "@/components/SkeletonLoading.vue";
import { API_HELPER } from "@/helpers/api.js";

// const observer = new ResizeObserver(function() {
//     var container = document.querySelector(".vueperslide--active");
//     console.log(container.scrollHeight);
//     document.getElementsByClassName('vueperslides__parallax-wrapper')[0].setAttribute("style","height:" + container.scrollHeight + "px");
// });
const MESSAGE_TYPE = "TrailInfo";

export default {
    metaInfo () {
        var result =
        { 
            title: this.trailContent ? `${this.trailContent.title} - Alcobaça` : "Alcobaça", 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.trailContent && this.trailContent.description ? UTILS_HELPER.htmlToText(this.trailContent.description) : this.$t("intro")},
                {name: 'keywords', content: this.trailContent && this.trailContent.days && this.trailContent.days.length && this.trailContent.categories && this.trailContent.categories.length ? this.trailContent.days.map(day=> day.title).join(", ") + ", " + this.trailContent.categories.map(category=> category.name).join(", ") : this.$t("vueRoutes.defaultKeywords")},
                 // Twitter Card
                {name: 'twitter:card', content: "summary"},
                {name: 'twitter:title', content: this.trailContent ? `${this.trailContent.title} - Alcobaça` : "Alcobaça"},
                {name: 'twitter:description', content: this.trailContent && this.trailContent.description ? UTILS_HELPER.htmlToText(this.trailContent.description) : this.$t("intro")},
                {name: 'twitter:image', content: this.featuredImage},
                //Facebook OpenGraph
                {property: 'og:title', content: this.trailContent ? `${this.trailContent.title} - Alcobaça` : "Alcobaça"},
                {property: 'og:site_name', content: 'Alcobaça'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content:  this.featuredImage},
                {property: 'og:description', content: this.trailContent && this.trailContent.description ? UTILS_HELPER.htmlToText(this.trailContent.description) : this.$t("intro")},
            ]
        } 

        if(this.$route.query.id)
        {
            result.link = 
            [
                {rel: 'alternate', hreflang: 'pt', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trailsDetail", 'pt')])}?id=${this.$route.query.id}`},
                {rel: 'alternate', hreflang: 'en', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trailsDetail", 'en')])}?id=${this.$route.query.id}`},
                {rel: 'alternate', hreflang: 'fr', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trailsDetail", 'fr')])}?id=${this.$route.query.id}`},
                {rel: 'alternate', hreflang: 'es', href: `${API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trailsDetail", 'es')])}?id=${this.$route.query.id}`}
            ];
        }

        return result;
    }, 
    // components:{TrailIntro,TrailDay, VueperSlides, VueperSlide},
    components:{TrailIntro, TrailDay, SkeletonLoading},
    data() {
        return {
            trailContent: null,
            // Message
            firstName: '',
            lastName: '',
            email: '',
            content: '',
            // Others
            submitted: false,
            loading: false,
            sent: false,
        }
    },
    validations() {
        return {
            firstName: { required },
            lastName: { required },
            email: { required, email },
        }
    },
    computed:
    {
        ...mapState('trails', ['trailOutput']),
        ...mapState('messages', ['processTask']),
        ...mapState({ 
            alertType: state => state.alert.type,
            alertOutput: state => state.alert.output
        }),
        errors: function()
        {
            return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
        },
        loadingContent: function()
        {
            return this.trailOutput == null;
        },
        featuredImage: function()
        {
            return this.trailContent && this.trailContent.days && this.trailContent.days.length && this.trailContent.days[0].files && this.trailContent.days[0].files.length ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.trailContent.days[0].files[0].path]) : null;
        }
    },
    methods: 
    {
        ...mapActions("trails", ["getDetail"]),
        initializeParams: function()
        {
            if(!this.$route.query.id)
            {
                router.push({ name: 'Trails' });
            }
            else
            {
                if(this.$route.params.trailContent)
                {
                    this.trailContent = this.$route.params.trailContent;
                }
                else
                {
                    this.getDetail(this.$route.query.id);
                }
            }
        },
        ...mapActions("messages", ["sendMessage"]),
        ...mapActions({
            clearAlert: 'alert/clear' 
        }),
        submitClicked: function()
        {
            if(!this.sent)
            {
                this.clearAlert();
                this.submitted = true;

                // stop here if form is invalid
                this.$v.$touch();

                if (this.$v.$invalid) {
                    return;
                }

                this.loading = true;
                const {firstName, lastName, email} = this;
                const content = this.trailContent.title;
                const type = MESSAGE_TYPE;

                this.sendMessage({firstName, lastName, email, content, type});
            }
        },
        hasError(fieldName)
        {
            return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
        },
        // onSlideChanged(val)
        //     {
        //         observer.disconnect();

        //         var currentSlide = val.currentSlide ? val.currentSlide.index : val;
        //         const container = document.querySelector('#trail-' + currentSlide);
        //         console.log(currentSlide);

        //         for (var i = 0; i < container.children.length; i++) {
        //             observer.observe(container.children[i]);
        //         }
        //     },
    },
    mounted: function()
    {
        this.initializeParams();
        // this.onSlideChanged(0);
    },
    watch:
    {
        processTask: function(val)
        {
            if(val != null)
            {
                this.loading = false;

                if(val)
                {
                    this.sent = true;
                }
                else if(val == false)
                {
                    this.$emit("error-alert-requested");
                }
            }
        },
        trailOutput: function(val)
        {
            if(val != null)
            {
                if(val && val.data)
                {
                    this.trailContent = val.data;
                }
                else
                {
                    router.push({ name: 'Trails' });
                }
            }
        }
    }
}
</script>

<style scoped>
/* >>>.trail-slide
{
    height: min-content;
}
[class^="icon-poisDetail_arrow_left"], [class*="icon-poisDetail_arrow_right"] {
  font-size: 250px;
}
.icon-poisDetail_arrow_left{
  margin-left: -260px;
}
.icon-poisDetail_arrow_right{
  margin-right: -260px;
} */

.row-reverse
{
    flex-direction: row-reverse;
}

.img img{
    max-height: 300px;
    width: 100%;
} 
.button1 {
  background-color: #962422;
  border: none;
  color: white;
  padding: 5px 10px;
  text-align: center;
  text-decoration: none !important;
  display: inline-block;
  font-size: 20px;
  margin: 4px 2px;
  cursor: pointer;
}
.button1.disabled
{
    opacity: 0.5;
}

</style>