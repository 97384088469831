<template>
    <div class="image container d-flex align-items-center justify-content-center">
        <div class="image-content d-flex flex-column justify-content-center">
            <!-- Close -->
            <div class="close-button cursor-pointer d-flex justify-content-end">
                <p @click="closeClicked"><img src="../assets/close.svg" alt="image" class="w-100"/><span>SAIR</span></p>
            </div>
            <!-- image -->
            <vueper-slides ref="fullscreenGallery" class="no-shadow" fixed-height="80vh" :touchable="true" :visible-slides="1" :bullets="false" fractions progress>
            <!-- Custom arrows -->
                <template v-slot:arrow-left>
                    <span class="icon-arrow-left"></span>
                </template>
                <template v-slot:arrow-right>
                    <span class="icon-arrow-right"></span>
                </template>
                <!-- content -->
                <vueper-slide v-for="(image, index) in gallery" :key="index" :image="image"></vueper-slide>
            </vueper-slides>
        </div>
    </div>
</template>

<script>
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    components:{VueperSlides, VueperSlide},
    props: {
        gallery: Array,
        startIndex: Number
    },
    mounted: function()
    {
        this.$refs.fullscreenGallery.goToSlide(this.startIndex);
    },
    methods:
    {
        closeClicked: function()
        {
            this.$emit("closeRequest");
        },
    },
    watch:
    {
        startIndex: function()
        {
            this.$refs.fullscreenGallery.goToSlide(this.startIndex);
        }
    }
}
</script>

<style scoped>
[class^="icon-arrow-left"], [class*="icon-arrow-right"] {
  font-size: 150px;
}
.icon-arrow-left:before {
  color: white;
}
.icon-arrow-right:before {
  color: white;
}
.icon-arrow-left{
    margin-left: -130px;
}
.icon-arrow-right{
    margin-right: -130px;
}
.close-button p
{
    position: fixed;
    right: 3%;
    top: 10%;
    width: 35px;
    cursor: pointer;
    font-size: 20px;
    color:white
}
.close-button span
{
    position: fixed;
    right: 3%;
    top: 15%;
}
.image{
  position:fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);  
  z-index: 999;
  margin: 0;
  max-width: unset;
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 5vw;
  padding-bottom: 5vw;
  gap: 16px;
}
.image-content
{
    height: 85vh;
    width: 58vw;
    min-height: 300px;
    top: 10%;
    position: fixed;
}
img
{
    width: 100%;
}
>>>.vueperslides__progress{
position: unset;
z-index: 0;
height: unset;
color: unset;
}
>>>.vueperslides__progress > *{
    position: unset;
    background: unset;
    transition: unset;
}
>>>.vueperslides__fractions{
    top: 0%;
    left: unset;
    right: -15%;
    border-radius: unset;
    background: unset;
    border: unset;
    padding: 0;
    font-size: 35px;
}
>>>.vueperslides__fractions::first-letter {
  font-size: 150%;
}
>>>.vueperslide {
    background-size: contain;
    background-repeat: no-repeat;
}
@media (max-width: 768px)
{
.close-button p {
    top: 12%;
  }

.close-button span {
    top: 18%;
  }

>>>.vueperslides__fractions {
    top: 22%;
    right: -30%;
    font-size: 30px;
  }
}

</style>