import { ENVIRONMENT_HELPER } from "@/helpers/environment.js";
import i18n from '@/helpers/i18n';
import router from '@/router';

export const IS_LOADING_STATUS = "loading";

export const API_HELPER = {
    abortController: new AbortController(),
    pathJoin,
    apiCall
};

function pathJoin(parts)
{
    return parts.map((part, i) => 
    {
        if (i === 0) {
          return part.toString().trim().replace(/[/]*$/g, '')
        } else {
          return part.toString().trim().replace(/(^[/]*|[/]*$)/g, '')
        }
    }).filter(x=>x.length).join('/');
}

/* Handles 1) request cancellation 2) fetch url 3) error handler */
function apiCall(url, queryParams, requestOptions, canBeAborted)
{
    // Validate parameters
    if(!url || !requestOptions)
    {
        throw("The necessary info for the api call was not provided!");
    }

    if(ENVIRONMENT_HELPER.isDev()) { console.log(requestOptions) }

    canBeAborted = (canBeAborted === undefined || canBeAborted === null) ? true : canBeAborted;

    // Build query parameters
    var fullUrl = getFullUrl(url, queryParams);

    // Complete the request options adding signal so that the request can be aborted.
    if(canBeAborted)
    {
        requestOptions.signal = API_HELPER.abortController.signal;
    }

    if(!requestOptions.headers) 
    {
        requestOptions.headers = {};
    }
    requestOptions.headers["Accept-Language"] = i18n.locale;

    if(ENVIRONMENT_HELPER.isDev()) { console.log(fullUrl) }

    return fetch(fullUrl, requestOptions)
    .then(response =>{
        if(ENVIRONMENT_HELPER.isDev()) { console.log(response) }
        return response.text().then(text => {
            const data = text && JSON.parse(text);
            if(ENVIRONMENT_HELPER.isDev()) { console.log(data) }
            if (!response.ok) {
                var errorMsg;
                switch(response.status)
                {
                    case 500:
                    case 501:
                    case 502:
                    case 503:
                        {
                            router.push('/error');
                            break;
                        }
                    default:
                        {
                            errorMsg = data;
                            break;
                        }
                }

                return Promise.reject(errorMsg);
            }
    
            return data;
        });        
    })
    .catch((err) => 
    {
        if (err.name === 'AbortError') 
        {
            console.log("The request was aborted!");
        }
        else
        {
            if(err && err.Errors)
            {
                return Promise.reject(err);
            }
            else
            {
                return Promise.reject("Something went wrong, please try again. If the problem persists contact the support.");
            }
        }
    });
}

function getFullUrl(url, queryParams)
{
    var fullUrl = encodeURI(url);
    if(queryParams)
    {
        fullUrl += "?";
        for(var key in queryParams) 
        {
            var value = queryParams[key];

            if(Array.isArray(value))
            {
                value.forEach(function(item){
                    var encodedItem = encodeURIComponent(item);
                    fullUrl += `&${key}=${encodedItem}`;    
                });
            }
            else if(value || value == 0)
            {
                var encodedValue = encodeURIComponent(value);

                fullUrl += `&${key}=${encodedValue}`;
            }
        }
    }

    return fullUrl;
}