import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import Pois from '../views/Pois.vue';
import Events from '../views/Events.vue';
import EventsDetail from '../views/EventsDetail.vue';
import PoisDetail from '../views/PoisDetail.vue';
import Trails from '../views/Trails.vue';
import TrailsDetail from '../views/TrailsDetail.vue';
import Downloads from '../views/Downloads.vue';
import StaticPage from '../views/StaticPage.vue';
import ErrorPage from '../views/ErrorPage.vue';
import SearchResults from "../views/SearchResults.vue";
import i18n from '@/helpers/i18n';

//Router layout Header and footer
import AppHeader from "../layout/AppHeader.vue";
import AppFooter from "../layout/AppFooter.vue";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
   components: {
      header: AppHeader,
      default: Home,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.pois'),
    name: 'Pois',
    components: {
      header: AppHeader,
      default: Pois,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.poisDetail'),
    name: 'PoisDetail',
   components: {
      header: AppHeader,
      default: PoisDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.events'),
    name: 'Events',
   components: {
      header: AppHeader,
      default: Events,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.eventsDetail'),
    name: 'EventsDetail',
   components: {
      header: AppHeader,
      default: EventsDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.trails'),
    name: 'Trails',
   components: {
      header: AppHeader,
      default: Trails,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.trailsDetail'),
    name: 'TrailsDetail',
   components: {
      header: AppHeader,
      default: TrailsDetail,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.downloads'),
    name: 'Downloads',
   components: {
      header: AppHeader,
      default: Downloads,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.staticPage'),
    name: 'StaticPage',
   components: {
      header: AppHeader,
      default: StaticPage,
      footer: AppFooter
    }
  },
  {
    path: i18n.t('vueRoutes.searchResults'),
    name: 'SearchResults',
    components: {
      header: AppHeader,
      default: SearchResults,
      footer: AppFooter
    }
  },
  {
    path: '*',
    name: 'ErrorPage',
    components: {
      header: AppHeader,
      default: ErrorPage,
      footer: AppFooter
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// Scroll to top on route change
router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(from);

  next();
})

export default router
