import { EVENTS_SERVICE } from "@/services/events.service.js";

const state = { listOutput: null, eventOutput: null, dateListOutput: null };

const actions = {
    getList({ dispatch, commit }, inputGetEvents) {
        dispatch('alert/clear', null, { root: true });
        commit('listRequest');
        
        EVENTS_SERVICE.list(inputGetEvents)
            .then(
                output => {
                    commit('listSuccess', output);
                },
                error => {
                    commit('listFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDate({ dispatch, commit }, inputGetDates) {
        dispatch('alert/clear', null, { root: true });
        commit('dateListRequest');
        
        EVENTS_SERVICE.getDates(inputGetDates)
            .then(
                output => {
                    commit('dateListSuccess', output);
                },
                error => {
                    // console.log(error)
                    commit('dateListFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
    getDetail({ dispatch, commit }, eventsId) {
        dispatch('alert/clear', null, { root: true });
        commit('detailRequest');
        
        EVENTS_SERVICE.get(eventsId)
            .then(
                output => {
                    commit('detailSuccess', output);
                },
                error => {
                    commit('detailFailure', error);
                    dispatch('alert/error', error, { root: true });
                }
            );
    },
};

const mutations = {
    /* List */
    listRequest(state) {
        state.listOutput = null;
    },
    listSuccess(state, output) {
        state.listOutput = output;
    },
    listFailure(state) {
        state.listOutput = [];
    },
    /* Date */
    dateListRequest(state) {
        state.dateListOutput = null;
    },
    dateListSuccess(state, output) {
        state.dateListOutput = output;
    },
    dateListFailure(state) {
        state.dateListOutput = false;
    },
    /* Detail */
    detailRequest(state) {
        state.eventOutput = null;
    },
    detailSuccess(state, output) {
        state.eventOutput = output;
    },
    detailFailure(state) {
        state.eventOutput = false;
    },
};
export const events = {
    namespaced: true,
    state,
    actions,
    mutations
};