<template>
    <section>
        <div class="container-fluid">
            <div class="row my-5 justify-content-between align-items-center">
                <div class="col-6 col-md-4 col-lg-3 title title-shape">
                    <h2 class="align-self-center">{{$t("sideMenu.downloads")}}</h2>
                </div>
                <div id="document-filter-container" class="col-12 col-md-6 col-lg-8 categories align-items-center">
                    <DocumentsFilter @change="onTypeChanged"/>
                </div>
                <div id="go-back-container" class="col-6 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
                    <a @click="$router.back()" class="nav-link"><h5 class="color-primary cursor-pointer">{{ $t("voltar")}}</h5></a>
                </div>
            </div>
        </div>
        <!-- Loading -->
        <div v-if="loading" class="container">
            <SkeletonLoading v-for="(block, index) in (new Array(itemsPerPage))" :key="index"></SkeletonLoading>
        </div>
        <!-- content -->
        <div v-else class="container">
            <div v-for="document in documents" :key="document.id" class="col-12 mt-4 mb-2">
                <div class="row d-flex align-items-center">
                    <div class="col-12 col-lg-1">
                        <span class="icon-pdf"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span></span>
                    </div>
                    <div class="col-12 col-lg-11">
                        <h3>{{document.title}}</h3>
                        <p v-html="document.description"/>
                        <a :href="pdfFilePath" target="_blank">
                            <button class="btn-button" id="download">{{$t("download")}}</button>
                        </a>
                    </div>
                        <hr class="line mt-4">
                </div>
            </div>
            <NoResultsPlaceholder v-if="!documents.length"></NoResultsPlaceholder>
        </div>
        <div v-show="!loading && canLoadMore">
            <span @click="loadMore" class="icon-show-more d-block text-center mb-2"></span>
            <p @click="loadMore" class="d-block text-center">{{$t("carregarMais")}}</p>
        </div>
</section>
</template>

<script>
import DocumentsFilter from '@/components/DocumentsFilter.vue';
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import InputGetList from "@/classes/InputGetList";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import { mapActions, mapState } from "vuex";
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 40;

export default {
    metaInfo () {
      return { 
            title: this.$t("vueRoutes.downloadsTitle"), 
            titleTemplate: '%s - Alcobaça', 
            htmlAttrs: {
                lang: this.$i18n.locale,
            },
            meta: 
            [
                {name: 'description', content: this.$t("vueRoutes.downloadsDescription")},
                {name: 'keywords', content: this.$t("vueRoutes.downloadsKeywords")},

                // Twitter Card
                {name: 'twitter:card', content: "summary"},
                {name: 'twitter:title', content: this.$t("vueRoutes.downloadsTitle")},
                {name: 'twitter:description', content: this.$t("vueRoutes.downloadsDescription")},
                {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },

                //Facebook OpenGraph
                {property: 'og:title', content: this.$t("vueRoutes.downloadsTitle")},
                {property: 'og:site_name', content: 'Alcobaça'},
                {property: 'og:type', content: 'website'},
                {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
                {property: 'og:description', content: this.$t("vueRoutes.downloadsDescription")}
            ], 
            link: [
                {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.downloads", 'pt')])},
                {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.downloads", 'en')])},
                {rel: 'alternate', hreflang: 'fr', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.downloads", 'fr')])},
                {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.downloads", 'es')])}
            ]
        } 
    }, 
    components: {DocumentsFilter, NoResultsPlaceholder, SkeletonLoading},
    data() {
        return{
            title: null,
            itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
        }
    },
    methods: {
        ...mapActions({
            getDocuments: 'documents/getList',
        }),
        updateItemsRequested: function(type){
            const currentPage = 1;
            const searchText = '';
            const sorter = 2; // desc
            const propertyName = "id";
            
            //Make request
            var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);
            input.type = type
            if(this.type){
                input.type = type
            }
            this.getDocuments(input);
        },
        onTypeChanged: function(type)
        {
            this.updateItemsRequested(type);
        },
        loadMore()
        {
            if(this.canLoadMore)
            {
                this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
            }
        },
    },
    computed:{
        ...mapState({ 
            documentsOutput: state => state.documents.listOutput,
        }),
        loading: function(){
            return this.documentsOutput == null;
        },
        documents: function() {
            return this.documentsOutput && this.documentsOutput.data && this.documentsOutput.data.documents ? this.documentsOutput.data.documents : [];
        },
        numDocuments: function(){
            return this.documentsOutput && this.documentsOutput.totalRecords ? this.documentsOutput.totalRecords : 0;
        },
        canLoadMore: function()
        {
            return this.numDocuments > this.itemsPerPage;
        },
        pdfFilePath: function()
        {
            return this.documents && this.documents[0] && this.documents.length && this.documents[0].path ? API_HELPER.pathJoin([process.env.VUE_APP_URL_ROOT, this.documents[0].path]) : null;
        },
    },
    mounted: function()
    {
        this.updateItemsRequested();
    },
    watch: 
    {
        itemsPerPage()
        {
            this.updateItemsRequested();
        },
    }
}
</script>

<style scoped>
/* Title */
.title-shape{
  height: 55px;
  border-top:2px solid #E31E24;
  border-right:2px solid #E31E24;
  border-bottom:2px solid #E31E24;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;
}

/* Header bar */
@media (max-width: 767px) /* Two columns */
{
  #document-filter-container
  {
    order: 3;
    margin: 2rem 0;
  }

  #go-back-container
  {
    order: 2;
  }
}

.icon-pdf{
    font-size: 130px;
}

.btn-button{
    background: #E31E24;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    color: white;
    border: none;
    padding: 2px 20px;
}

hr{
    background-color: unset;
    border: 0;
    opacity: 1;
}

hr.line{
    border-top: 1px dashed red;
}

[class*="icon-show-more"] {
  font-size: 80px;
  line-height: 25px;
}

.show-more{
  cursor: pointer;
}

.skeleton-box
{
    width: 100%;
    min-height: 200px;
    margin-bottom: 2rem;
}
</style>