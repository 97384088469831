import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from '@/helpers/i18n';

// JQuery
import 'jquery/src/jquery.js'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

Vue.config.productionTip = false
Vue.config.silent = true; // Necessary for ol.js

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VueMeta from 'vue-meta'
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

const options = {
  locale: i18n.locale,
  fonts: [
    {
      src: "https://fonts.googleapis.com/css?family=Ruda",
      family: 'Ruda',
      style: 'normal',
    },
  ],
};

Vue.use(options);

router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(from);

  if(to.name != "Home") // Home has special rules to run this code
  {
    setTimeout(function () {
      window.scrollTo(0, 0);
    },100);
  }

  next();
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
