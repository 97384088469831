<template>
<div>
    <div class="container-fluid">
      <div class="row my-5 justify-content-between align-items-center">
          <div class="col-6 col-md-4 col-lg-3 title title-shape">
              <h2 class="align-self-center">{{ $t("oQueFazer").toUpperCase()}}</h2>
          </div>
          <div id="category-filter-container" class="col-12 col-lg-8 categories align-items-center">
              <CategoryFilter @change="onCategoriesChanged" :type="type"/>
          </div>
          <div id="go-back-container" class="col-6 col-md-2 col-lg-1 d-flex justify-content-center align-items-center">
            <a @click="$router.back()" class="nav-link"><h5 class="color-primary cursor-pointer">{{ $t("voltar")}}</h5></a>
          </div>
      </div>
    </div>
    <!-- Loading -->
    <div v-if="loading" class="trail-container d-flex flex-wrap mt-2 container">
      <SkeletonLoading v-for="(block, index) in (new Array(itemsPerPage))" :key="index"></SkeletonLoading>
    </div>
    <!-- Content -->
    <div v-else class="trail-container d-flex flex-wrap mt-2 container">
      <TrailThumb  class="slider-thumb" v-for="content in trails" :key="content.id" :trailContent="content"/>
      <NoResultsPlaceholder v-if="!trails.length"></NoResultsPlaceholder>
    </div>
    <div v-show="!loading && canLoadMore">
      <span @click="loadMore" class="icon-show-more cursor-trailnter d-block text-center mb-2"></span>
      <p @click="loadMore" class="d-block text-center">{{$t("carregarMais")}}</p>
    </div>
</div>
</template>

<script>
import TrailThumb from "@/components/TrailThumb.vue";
import CategoryFilter from "@/components/CategoryFilter.vue";
import NoResultsPlaceholder from "@/components/NoResultsPlaceholder.vue";
import SkeletonLoading from "@/components/SkeletonLoading.vue"
import { mapActions, mapState } from "vuex";
import InputGetList from "@/classes/InputGetList";
import { API_HELPER } from "@/helpers/api.js";

const DEFAULT_ITEMS_PER_PAGE = 8;
const CATEGORY_TYPE = "Trail";

export default {
  metaInfo () {
    return { 
          title: this.$t("vueRoutes.trailsTitle"), 
          titleTemplate: '%s - Alcobaça', 
          htmlAttrs: {
            lang: this.$i18n.locale,
          },
          meta: 
          [
            {name: 'description', content: this.$t("vueRoutes.trailsDescription")},
            {name: 'keywords', content: this.trails.length ? this.trails.map(x=> x.title).join(", ") : this.$t("vueRoutes.defaultKeywords")},
            // Twitter Card
            {name: 'twitter:card', content: "summary"},
            {name: 'twitter:title', content: this.$t("vueRoutes.trailsTitle")},
            {name: 'twitter:description', content: this.$t("vueRoutes.trailsDescription")},
            {name: 'twitter:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
            //Facebook OpenGraph
            {property: 'og:title', content: this.$t("vueRoutes.trailsTitle")},
            {property: 'og:site_name', content: 'Alcobaça'},
            {property: 'og:type', content: 'website'},
            {property: 'og:image', content: API_HELPER.pathJoin([process.env.VUE_APP_URL, "assets/hero/hero.jpg"]) },
            {property: 'og:description', content: this.$t("vueRoutes.trailsDescription")},
          ], 
          link: [
            {rel: 'alternate', hreflang: 'pt', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trails", 'pt')])},
            {rel: 'alternate', hreflang: 'en', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trails", 'en')])},
            {rel: 'alternate', hreflang: 'fr', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trails", 'fr')])},
            {rel: 'alternate', hreflang: 'es', href: API_HELPER.pathJoin([process.env.VUE_APP_URL, this.$t("vueRoutes.trails", 'es')])}
          ]
      } 
  }, 
  components:{TrailThumb, CategoryFilter, NoResultsPlaceholder, SkeletonLoading},
  data(){
      return{
        type: CATEGORY_TYPE,
        itemsPerPage: DEFAULT_ITEMS_PER_PAGE,
      }
  },
  methods:{
  ...mapActions({
      getTrails: 'trails/getList',
    }),
  updateItemsRequested: function(categories)
  {
    const currentPage = 1;
    const searchText = '';
    const sorter = 2; // desc
    const propertyName = "id";
    
    //Make request
    var input = new InputGetList(this.itemsPerPage, currentPage, searchText, sorter, propertyName);
    input.visible = true;
    if(categories && categories.length)
    {
      input.categories = categories;
    }

    this.getTrails(input);
  },
  loadMore()
  {
    if(this.canLoadMore)
    {
      this.itemsPerPage += DEFAULT_ITEMS_PER_PAGE; 
    }
  },
  onCategoriesChanged: function(categories)
  {
    this.updateItemsRequested(categories);
  }
},
computed:{
  ...mapState({ 
    trailsOutput: state => state.trails.listOutput,
    categoriesOutput: state => state.categories.listOutput
  }),
  loading: function(){
    return this.trailsOutput == null || this.categoriesOutput == null;
    },
  trails: function() {
    return this.trailsOutput && this.trailsOutput.data && this.trailsOutput.data.trails ? this.trailsOutput.data.trails : [];
  },
  numTrails: function(){
      return this.trailsOutput && this.trailsOutput.totalRecords ? this.trailsOutput.totalRecords : 0;
    },
  canLoadMore: function()
    {
      return this.numTrails > this.itemsPerPage;
    },
  },
  mounted: function()
  {
    // Get trails
    this.updateItemsRequested();
  },
  watch: 
  {
    itemsPerPage()
    {
      this.updateItemsRequested();
    }
  }
}
</script>

<style scoped>
/* Title */
.title-shape{
  height: 55px;
  border-top:2px solid #E31E24;
  border-right:2px solid #E31E24;
  border-bottom:2px solid #E31E24;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}
.title{
    justify-content: center;
    display: flex;
}
.title h2{
    margin:0;

}
/* Header bar */
@media (max-width: 768px) /* Two columns */
{
  #category-filter-container
  {
    order: 3;
    margin: 2rem 0;
  }

  #go-back-container
  {
    order: 2;
  }
}

/* Size of trail elements: these are not done using bootstrap to have correct alignments, using gap */
.trail-container /* Four columns */
{
  gap: 4%;
}
.trail-container > .slider-thumb,
.trail-container > span
{
  width: 22%;
  margin-bottom: 4%;
}
@media (max-width: 1199px) 
{
  .trail-container
  {
    gap: 5%;
    padding: 0 1rem;
  }

  .trail-container > .slider-thumb,
  .trail-container > span
  {
    width: 30%;
    max-width: 30%;
    margin-bottom: 5%;
  }
}
@media (max-width: 767px) /* Two columns */
{
  .trail-container
  {
    gap: 10%;
    padding: 0 1rem;
  }

  .trail-container > .slider-thumb,
  .trail-container > span
  {
    width: 45%;
    max-width: 45%;
    margin-bottom: 10%;
  }
}
@media (max-width: 575px) /* Two columns */
{
  #category-filter-container
  {
    margin-bottom: 5rem;
  }

  .trail-container
  {
    padding: 0 1rem;
  }

  .trail-container > .slider-thumb,
  .trail-container > span
  {
    width: 95%;
    max-width: 95%;
    margin-bottom: 50%;
  }
  .title h2{
   font-size: 20px;
  }
}

/* Others */
>>>.overlay{
    visibility: hidden;
    transition: 1s ease;
    background-color: hsla(1, 63%, 36%, 0);
    max-height: 45px;
    top: 0;
}
>>>.overlay_2 {
    visibility: hidden;
    transition: 1s ease;
    background-color: hsla(1, 63%, 36%, 0);
    max-height: 80px;
    bottom: 0;
}

[class*="icon-show-more"] {
  font-size: 80px;
  line-height: 25px;
}

.skeleton-box
{
  min-height: 275px;
  border-radius: 20px;
}
</style>