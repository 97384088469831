<template>
<div>
    <!-- Navbar -->
      <b-sidebar id="sidebar-left" left no-header>
        <div class="p-5 h-100">
          <div class="d-flex flex-column h-100">
            <div v-b-toggle.sidebar-left :class="{ added_button: scrollPosition > 50 }"></div>
            <router-link class="nav-link" :to="{ name: 'StaticPage', query: {id: '6'}}">{{$t("sideMenu.sobreAlcobaca")}}</router-link>
            <router-link class="nav-link" :to="{ name: 'Trails'}">{{ $t("sideMenu.oQueFazer")}}</router-link>
            <router-link class="nav-link" :to="{ name: 'Pois', query: { type: 2 }}">{{ $t("sideMenu.oQueVer")}}</router-link>
            <router-link class="nav-link" :to="{ name: 'Pois', query: { type: 4 }}">{{ $t("sideMenu.ondeFicar")}}</router-link>
            <router-link class="nav-link" :to="{ name: 'Pois', query: { type: 3 }}">{{ $t("sideMenu.ondeComer")}}</router-link>
            <router-link class="nav-link" :to="{ name: 'Events'}">{{ $t("footer.agenda")}}</router-link>
            <!-- This logic, using the default hash navigation, is not working correctly, so a custom implementation was done -->
            <!-- <router-link class="nav-link" to="/#support">{{ $t("sideMenu.faleConnosco")}}</router-link>
            <router-link class="nav-link" to="/#directions">{{ $t("sideMenu.comoChegar")}}</router-link> -->
            <a class="nav-link cursor-pointer" @click="$emit('hash-requested', 'support')">{{ $t("sideMenu.faleConnosco")}}</a>
            <a class="nav-link cursor-pointer" @click="$emit('hash-requested', 'directions')">{{ $t("sideMenu.comoChegar")}}</a>
            <router-link class="nav-link" :to="{ name: 'Downloads'}">{{ $t("sideMenu.downloads")}}</router-link>
            <IconsSocial class="icon px-2"/>
          </div>
        </div>
      </b-sidebar>
    </div>
</template>

<script>
import IconsSocial from "@/components/IconsSocial.vue";
export default {
  components:{IconsSocial},
  data(){
    return{
      drag: false,
      scrollPosition: null,

    }
  },
  mounted() {
      document.addEventListener('mousedown', () => this.drag = false);
      document.addEventListener('mousemove', () => this.drag = true);
      document.addEventListener('mouseup', this.handleMouseUp);
      window.addEventListener("scroll", this.updateScroll);

  },
  destroyed() {
      document.removeEventListener('mousedown', () => this.drag = false);
      document.removeEventListener('mousemove', () => this.drag = true);
      document.removeEventListener('mouseup', this.handleMouseUp);
  },
  methods: 
  {
    handleMouseUp(event) {
      var toggle = document.getElementById("navbar-toggle");
      if(toggle.classList.contains("not-collapsed") && !this.drag)
      {
        var element = document.getElementById("sidebar-left");
        var isClickInside = element.contains(event.target);
        var isAnchor = event.target.tagName == 'a';
        var isToggle = event.target.classList.contains("sizebtn");

        if (!isToggle && (!isClickInside || (isClickInside && isAnchor))) 
        {
          document.getElementById("btn-close").click();
        }
      }

      this.drag = false;
    },
    simulateHamburgerClick()
    {
      document.getElementById("btn-close").click();
    },
    updateScroll() {
      this.scrollPosition = window.scrollY;
    },
  }
}
</script>

<style scoped>
>>>.icon-face::before {
    color: black;
}
>>>.icon-insta::before {
    color: black;
}
>>>.icon-youtube::before {
    color: black;
}
>>>.icon-ico::before {
    color: black;
}
.icon{
  position: static;
  
}
>>>.user-text {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 60%;
  left: 35%;
  font-size: 11px;
}
>>>.b-sidebar{
    background-color:rgb(227, 227, 227) !important;  
    box-shadow: 0 0 10px rgba(0,0,0,0.75);
}
>>>.nav-link {
    color: black !important;
    font-size: 23px;
    text-decoration:  none;
}
>>>.b-sidebar.b-sidebar-left > .b-sidebar-header .close{
    margin-left: auto !important;
    margin-right: 0 !important;
}
>>>.b-sidebar-outer {
    z-index: 999;
}
>>>.b-sidebar > .b-sidebar-body {
    padding-top: 70px;
}
>>>.b-sidebar-body a{
  margin: 4px 0;
}
.added_button{
  font-size: 30px;
  position: absolute;
  top: 5%;
  color: black;
  left: 20%;
  cursor: pointer;
}
.added_button::after{
  content: ' X ';
}
</style>