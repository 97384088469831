<template>
  <div id="app" class="d-flex flex-column">
      <router-view name="header" @hash-requested="onHashRequested"></router-view>
      <router-view :hash="hash" @hash-requested="onHashRequested" class="flex-grow-1" ref="container" @success-alert-requested="showSuccessAlert" @warning-alert-requested="showWarningAlert" @error-alert-requested="showErrorAlert"/>
      <router-view name="footer" @hash-requested="onHashRequested"></router-view>
  </div>
</template>
<script>
import Vue from "vue";
import ResultPopup from "@/components/ResultPopup.vue"


const SUCCESS = 0;
const WARNING = 1;
const ERROR = 2;

export default {
  name: 'Home',
  data() {
    return {
      hash: ''
    };
  },
  methods:
  {
    showSuccessAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback})
    {
      this.showAlert(SUCCESS, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showWarningAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback})
    {
      this.showAlert(WARNING, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showErrorAlert({message, primaryButton, primaryCallback, secondaryButton, secondaryCallback})
    {
      this.showAlert(ERROR, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback);
    },
    showAlert(type, message, primaryButton, primaryCallback, secondaryButton, secondaryCallback)
    {
      var ComponentClass = Vue.extend(ResultPopup)
      var instance = new ComponentClass({
        propsData: { type: type, message: message, primaryButtonText: primaryButton, primaryButtonCallback: primaryCallback, secondaryButtonText: secondaryButton, secondaryButtonCallback: secondaryCallback }
      })
      instance.$mount() // pass nothing
      this.$refs.container.$el.appendChild(instance.$el)
    },
    onHashRequested: function(hash)
    {
      if(this.$router.name != "Home")
      {
        this.$router.push("/");
      }

      this.hash = hash;
    }
  },
}

</script>

<style>
@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?x90kt3');
  src:  url('fonts/icomoon.eot?x90kt3#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?x90kt3') format('truetype'),
    url('fonts/icomoon.woff?x90kt3') format('woff'),
    url('fonts/icomoon.svg?x90kt3#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pdf .path1:before {
  content: "\e90e";
  color: rgba(180, 0, 0, 0.9);
}
.icon-pdf .path2:before {
  content: "\e90f";
  margin-left: -0.70703125em;
  color: rgb(254, 254, 254);
}
.icon-pdf .path3:before {
  content: "\e910";
  margin-left: -0.70703125em;
  color: rgb(0, 0, 0);
}
.icon-pdf .path4:before {
  content: "\e911";
  margin-left: -0.70703125em;
  color: rgba(180, 0, 0, 0.9);
}
.icon-pdf .path5:before {
  content: "\e912";
  margin-left: -0.70703125em;
  color: rgba(180, 0, 0, 0.9);
  opacity: 0.4;
}
.icon-arrow-category-left:before {
  content: "\e90c";
  color: #b72827;
}
.icon-arrow-category-right:before {
  content: "\e90d";
  color: #b72827;
}
.icon-poisDetail_arrow_left:before {
  content: "\e90a";
  color: #902420;
}
.icon-poisDetail_arrow_right:before {
  content: "\e90b";
  color: #902420;
}
.icon-show-more:before {
  content: "\e909";
}
.icon-arrow-right:before {
  content: "\e907";
  color: #8f1913;
}
.icon-arrow-left:before {
  content: "\e908";
  color: #8f1913;
}
.icon-ico:before {
  content: "\e903";
  color: black;
}
.icon-face:before {
  content: "\e904";
  color: black;
}
.icon-insta:before {
  content: "\e905";
  color: black;
}
.icon-youtube:before {
  content: "\e906";
  color: black;
}
.icon-search:before {
  content: "\e901";
  color: #fff;
  font-size: 50px;
}
.icon-menu:before {
  content: "\e900";
  color: #fff;
  font-size: 69px;
}

/* Defaults */
@import url('https://fonts.googleapis.com/css?family=Ruda');

html, body, #app {
  font-family: 'Ruda', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}
.background-color-primary{
    background-color: #A10D0D;
}
.color-primary{
  color: #A10D0D !important;
}
.background-color-primary-footer{
  background: linear-gradient(to right, rgba(180, 0, 0, 0.9), #E31E24);
}
.cursor-pointer
{
  cursor: pointer;
}
.text-white
{
  color: white;
}

</style>
